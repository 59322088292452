import React, {  useState } from "react";
import OrderList from "../MyOrders/OrderList";


const MyOrderList = () => {
  const [current, setCurrent] = useState(0);
const[status,setStatus]=useState("Delivered")

  const Tabs = [
    {
      key: 1,
      name: "Delivered",
      component: (
       <p>jhgegjkhe</p>
      ),
    },
    {
      key: 2,
      name: "Processing",
      component: (
        <p>jhgegjkhe</p>
       ),
    },
    {
      key: 3,
      name: "Cancelled",
      component: (
        <p>jhgegjkhe</p>
       ),
    },
  ];

  return (
    <>
      <div className="p-2 ">
      <div className="mt-4 hidden w-1/2 bg-white rounded-lg md:grid grid-cols-1 md:grid-cols-3 place-items-start md:place-items-center gap-y-4">
        {Tabs.map((i, index) => {
          return (
            <div key={index} className="w-full text-center">
              <p
                onClick={() => {
                  setCurrent(index);
                  setStatus(i.name)
                }}
                className={`cursor-pointer  transition-all ${i.key-1===current?"font-bold":""}  ${
                  i.key - 1 === current
                    ? "bg-black tab-active  text-white"
                    : "border border-[#EBEBEB]"
                }  py-1 `}
              >
                {i.name}
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-2  md:hidden grid grid-cols-3  place-items-center md:place-items-center gap-4">
        {Tabs.map((i, index) => {
          return (
            <div key={index} className="w-full text-center">
              <p
                onClick={() => {
                  setCurrent(index);
                  setStatus(i.name)

                }}
                className={`w-full relative ${
                  i.key - 1 === current
                    ? "border-b tab-active border-b-textColor text-primary"
                    : "border-b text-gray-500 border-b-gray-500"
                } cursor-pointer  py-2 `}
              >
                {i.name}
              </p>
            </div>
          );
        })}
      </div>
      <OrderList status={status}/>
      </div>
    </>
  );
};

export default MyOrderList;
