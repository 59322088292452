import { AutoComplete, Drawer } from "antd";
import React, { useCallback,useState } from "react";
import { IoMdClose } from "react-icons/io";
import { OrderSearch } from "../ApiServices/Apifun";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import { createImageUrl } from "../Utils";
import { Images } from "../ImageController/Index";

const SearchDrawer = ({ open, setIsOpen }) => {
  const navigation = useNavigate();
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState("");
  let debounceTimer;
  const handleSearch = useCallback((e) => {
    const payload = {
      keyword: e,
    };
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      // Trigger the search function after the debounce delay (e.g., 300 milliseconds)
      OrderSearch(payload)
        .then((res) => {
          if (res.data.length !== 0) {
            setList(
              res.data.map((i, index) => {
                return {
                  label: (
                    <div
                      onClick={() => {
                        navigation(`/productDesc/${i.slug}`, {
                          state: { id: i.slug },
                        });
                        setIsOpen(false);
                        setKeyword("");
                      }}
                      className="grid text-black font-semibold grid-cols-2 md:grid-cols-2 place-items-center"
                    >
                      <LazyLoadImage
                      loading="lazy"
                      onError={(err)=>{
                        err.target.src=Images.NoImage
                        err.target.alt="No Image"
                      }}
                        className="w-12 rounded-full h-12 object-cover  "
                          src={createImageUrl(i.default_image[0])}
                        crossOrigin="anonymous"
                      />
                      <div className="w-full">
                        <p className="capitalize w-full text-xs text-gray-800 font-medium">
                          {i.product_name ?? "-"}
                        </p>
                        <p className="capitalize w-full text-xs text-gray-600 font-thin">
                          {i.product_name ?? "-"}
                        </p>
                      </div>
                    </div>
                  ),
                  value: i.product_name,
                };
              })
            );
          } 
          else {
            setList([
              
            ]);
          }
        })
        .catch((err) => console.log(err));
    }, 300);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Drawer
      closeIcon={null}
      height={68}
      className="searchDrawer"
      onClose={() => {
        setKeyword("");
        setIsOpen(false)
      }}
      style={{padding:0}}
      maskClosable={true}
      maskMotion={true}
      placement="top"
      open={open}
    >
      <div className="flex bg-primary p-4 justify-between items-center">
        <div className=" hidden md:flex cursor-pointer  font-bold ">
        <LazyLoadImage src={Images.Logo} className="w-12" alt="logo"/>

        </div>
        <div className="  md:flex justify-center items-center w-9/12 lg:w-1/4">
          <AutoComplete
            style={{ borderRadius: "0px" }}
            className="w-full border-none hover:border-none searchbar"
            value={keyword}
            onSearch={(e) => {
              setKeyword(e);
              handleSearch(e);
            }}
            placeholder="Search Product"
            options={list}
          />
          {/* <BaseButton onClick={handleSearch} text={"Search"} /> */}
        </div>
        <div
          onClick={() => {
            setIsOpen(false);
            setKeyword("");
          }}
          className="bg-white rounded-full   cursor-pointer p-2"
        >
          <IoMdClose size={20} />
        </div>
      </div>
    </Drawer>
  );
};

export default SearchDrawer;
