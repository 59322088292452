import React, { useEffect, useState } from "react";
import {
  GetBrandList,
  getProductListByCategory,
} from "../../ApiServices/Apifun";
import { NavLink, useLocation } from "react-router-dom";
import { Skeleton } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  createImageUrl,
  getDecryptedLocalStorage,
  handleAddToFavourite,
  handleRemoveFromFavourite,
} from "../../Utils";
import { Images } from "../../ImageController/Index";
import { useDispatch, useSelector } from "react-redux";
import CommonPagination from "../../CommmonComponents/CommonPagination";
import QuickOverView from "../Home/QuickOverView";
import { setOpenLoginModal } from "../../Redux/commonSlice";
import CommonCard from "../../CommmonComponents/CommonCard";
import FilterDrawer from "./FilterDrawer";
import { FaFilter } from "react-icons/fa";
import Filtercomponent from "./Filtercomponent";
import {  setSelectedCategory } from "../../Redux/userSlice";

const Category = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedId, setSelectedId] = useState(location.state?.id);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [brandList, setBrandList] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [current, setCurrent] = useState(1);
  const [openQuick, setOpenQuick] = useState(false);
  const [productId, setProductId] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  // const [limit, setLimit] = useState(10);
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const getfilters = useSelector((state) => state.user.filters);

  const getCategoryProducts = () => {
    const combine = [...getfilters.price];
    setShowSkeleton(true);
    getProductListByCategory({
      category_id: selectedId,
      currency :getDecryptedLocalStorage('country')==="IN"?'INR':"USD",
      brandId: getfilters.brandList.length > 0 ? getfilters.brandList : [],
      min_rating: getfilters.rating,
      max_price:
        combine.sort((a, b) => {
          if (a.min > b.min) {
            return 1;
          } else {
            return -1;
          }
        })[combine.length - 1]?.max ?? null,
      min_price:
        combine.sort((a, b) => {
          if (a.min > b.min) {
            return 1;
          } else {
            return -1;
          }
        })[0]?.min ?? null,
      page: current,
      limit: 10,
    })
      .then((res) => {
        if (res.status) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setCurrent(res.currentPage);
          setTotalPages(res.totalRecords);
          setCategoryList(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTimeout(() => {
          setShowSkeleton(false);
        }, 300);
      });
  };

  const getBrands = () => {
    GetBrandList()
      .then((res) => {
        if (res.status) {
          setBrandList(res.data);
        }
      })
      .catch((err) => console.log(err))
  };
  useEffect(() => {
    getBrands();
    return () => dispatch(setSelectedCategory(null));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getCategoryProducts();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, selectedId, getfilters]);
  useEffect(() => {
    setSelectedId(location.state.id);
  }, [location]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [current]);

  return (
    <>
      <div className="md:container md:mx-auto mx-4  select-none">
        {/* <div>
          {categoryList.length !== 0 ? (
            <div className="flex justify-center items-center">
              <p className="text-3xl md:text-5xl capitalize sectionHeading relative text-textColor font-heading  rounded-sm flex justify-center items-center  cursor-pointer ">
                {categoryList[0]?.category_name}
              </p>
            </div>
          ) : (
            <p></p>
            // <Skeleton.Input active  shape="square" className="w-52" />
          )}
        </div> */}

        <>
          <div className="flex lg:hidden justify-between w-full reounded-md items-center bg-primary p-4">
            <p className="text-white text-lg font-semibold">Filters</p>
            <FaFilter
              onClick={() => setOpenFilterDrawer(true)}
              className="cursor-pointer"
              color="white"
            />
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-5 mt-4  md:container md:mx-auto place-items-start">
            <div className="hidden lg:block">
              <Filtercomponent
                brands={brandList}
                selectedId={selectedId}
                setSelectedId={setSelectedId}
                data={location?.state?.completed?.child_category}
              />
            </div>

            {showSkeleton ? (
              <div className="grid col-span-4 grid-cols-1 mt-0 sm:grid-cols-2 w-full lg:grid-cols-4 gap-4 place-items-center">
                {[1, 2, 3, 4, 5, 6, 4, 4].map((i,index) => (
                  <div key={index} className="  h-[200px]   w-full">
                    <Skeleton.Button
                      active={true}
                      size={200}
                      shape={"default"}
                      block={false}
                    />
                  </div>
                ))}
              </div>
            ) : categoryList?.length !== 0 ? (
              <>
                <div className="grid col-span-4 grid-cols-1 mt-0 sm:grid-cols-2 w-full lg:grid-cols-3 xl:grid-cols-4 gap-4 place-items-center">
                  {categoryList?.map((i, index) => {
                    // const image = JSON.parse(i.default_image);
                    const image =
                      typeof i.default_image == "string"
                        ? JSON.parse(i.default_image)
                        : i.default_image;

                    return (
                      <div key={index} className=" w-full">
                        <CommonCard
                          index={index}
                          image={
                            image?.length !== 0
                              ? createImageUrl(image[0])
                              : Images.NoImage
                          }
                          handleFav={() =>
                            isLoggedIn
                              ? !i.is_favourite
                                ? handleAddToFavourite(
                                    i.product_id,
                                    getCategoryProducts
                                  )
                                : handleRemoveFromFavourite(
                                    i.product_id,
                                    getCategoryProducts
                                  )
                              : dispatch(setOpenLoginModal(true))
                          }
                          data={i}
                          setslug={setProductId}
                          setopen={setOpenQuick}
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <div className="flex flex-col w-full col-span-4 justify-center items-center">
                <div className=" rounded-full">
                  <LazyLoadImage
                    src={Images.NoFavourite}
                    className="p-8 "
                    alt="no favourite"
                  />
                </div>
                <p className="text-textColor font-bold">No Items Found</p>
                <NavLink to="/">
                  <p className="text-textColor font-thin text-xs underline cursor-pointer">
                    Explore Product
                  </p>
                </NavLink>
              </div>
            )}
          </div>
        </>
        {categoryList.length !== 0 && (
          <div className="flex justify-end items-center my-8">
            <CommonPagination
              defaultPageSize={10}
              current={current}
              pageSize={10}
              setCurrent={setCurrent}
              totalPages={totalPages}
            />
          </div>
        )}
      </div>
      {openQuick && (
        <QuickOverView
          open={openQuick}
          setopen={setOpenQuick}
          productId={productId}
        />
      )}
      {openFilterDrawer && (
        <FilterDrawer
          brands={brandList}
          open={openFilterDrawer}
          setOpen={setOpenFilterDrawer}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          data={location?.state?.completed?.child_category}
        />
      )}
    </>
  );
};

export default Category;
