import React, { useEffect, useState } from "react";
import DetailTabBar from "./DetailTab";
import WriteReviewModal from "../../components/WriteReview";
import { Rate, Tooltip } from "antd";
import { createImageUrl, trimString } from "../../Utils";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Images } from "../../ImageController/Index";

const ProductDetails = ({ productDetail, apiCall }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [specs, setSpecs] = useState([]);
  const [openReviewModal, setOpenreviewModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  // const userProfile = useSelector((state) => state.user.userProfile);
  const userDetail = useSelector((state) => state.user.userDetail);

  useEffect(() => {
    if (productDetail) {
      const groupedSpecs = productDetail.productSpecificationDetail?.reduce(
        (groups, item) => {
          const { spec_group } = item;
          if (!groups[spec_group]) {
            groups[spec_group] = [];
          }
          groups[spec_group].push(item);
          return groups;
        },
        {}
      );

      setSpecs(groupedSpecs);
    }
  }, [productDetail]);
  return (
    <>
      <div className="shadow-sm shadow-gray-300 px-4 mt-4 py-2">
        <div className="mt-2">
          <DetailTabBar setActiveTab={setActiveTab} activeTab={activeTab} />
        </div>
        {activeTab === 1 ? (
          <div className="w-full  max-h-[300px] overflow-scroll ">
            {Object.keys(specs).length !== 0 ? (
              Object.keys(specs).map((key, index) => {
                return (
                    <div key={index}>
                      <p className="font-medium text-sm mt-2 text-gray-600">
                        {key}
                      </p>
                      {specs[key].map((i, index) => (
                        <div key={index} className="flex mt-2 justify-start  items-center">
                          <Tooltip placement="leftBottom" title={i.spec_name}>
                            <p className="border  flex justify-start pl-5 items-center h-10  font-semibold text-sm  w-1/2 border-gray-300">
                              {trimString(i.spec_name, 25)}
                            </p>
                          </Tooltip>
                          <Tooltip placement="rightTop" title={i.spec_value}>
                            <p className="border  flex justify-start pl-5 items-center h-10 text-sm w-1/2 border-gray-300">
                              {/* {i.spec_value} */}
                              {trimString(i.spec_value, 25)}
                            </p>
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                );
              })
            ) : (
              <div className="flex justify-center items-center font-semibold">
                <p>No speicification</p>
              </div>
            )}
          </div>
        ) : activeTab === 2 ? (
          <>
            <div className=" max-h-[300px] overflow-scroll">
              {productDetail.reviewList.length !== 0 ? (
                productDetail.reviewList.map((i, index) => (
                  <div
                    className="hover:bg-gray-100 shadow-sm shadow-gray-300 cursor-pointer transition-all p-3"
                    key={index}
                  >
                    <div className="flex mt-2  justify-between items-center ">
                      <div className="flex justify-start items-start gap-x-2">
                        <LazyLoadImage
                         onError={(err) => {
                          err.target.src = Images.NoImage;
                          err.target.alt = "No Image";
                        }}
                          src={createImageUrl(i.image, "userProfile")}
                          className="w-6 rounded-full"
                          crossOrigin="anonymous"
                        />
                        <p className="text-sm capitalize font-semibold">
                          {userDetail?.userId ===
                          i.buyer_id
                            ? "Me"
                            : i.buyer_fullname}
                        </p>
                      </div>
                      <Rate
                        disabled
                        allowHalf
                        value={Number(i.rating)}
                        defaultValue={Number(i.rating)}
                      />
                    </div>
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-sm mt-2 font-semibold">{i.title}</p>
                        <Tooltip placement="leftTop" title={i.user_review}>
                          <p className="text-xs overflow-hidden   font-medium">
                            {trimString(i.user_review, 200)}
                          </p>
                        </Tooltip>
                      </div>
                      {userDetail?.userId ===
                        i.buyer_id && (
                        <div>
                          <p
                            onClick={() => {
                              setSelectedReview({
                                product_id:productDetail.productDetail.id,
                                record_id: i.review_id,
                                review_title: i.title,
                                review_text: i.user_review,
                                rating: i.rating,
                              });
                              setOpenreviewModal(true);
                            }}
                            className="text-sm underline  mt-2 font-semibold"
                          >
                            Edit
                          </p>
                        </div>
                      )}
                    </div>
                    {/* <hr className="mt-2" /> */}
                  </div>
                ))
              ) : (
                <div className="flex justify-center items-center font-semibold">
                  <p>No Reviews</p>
                </div>
              )}
            </div>
          </>
        ) : activeTab === 3 ? (
          <p>jkjjj</p>
        ) : activeTab === 5 ? (
          <p>jkjjj</p>
        ) : (
          <p>jkjjj</p>
        )}
      </div>
      {openReviewModal && (
        <WriteReviewModal
          data={selectedReview}
          apiCall={apiCall}
          setOpen={setOpenreviewModal}
          open={openReviewModal}
        />
      )}
    </>
  );
};

export default ProductDetails;
