import React from "react";
import { Images } from "../../ImageController/Index";
import { useSelector } from "react-redux";
import { dispatchtoast } from "../../Utils";

const Referrals = () => {
  const userDetail = useSelector((state) => state.user.userProfile);
  console.log({ userDetail });
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 place-items-center overflow-scroll">
        <div className="flex flex-col w-full justify-center items-center">
          <p className="text-lg font-bold">REFER YOUR</p>
          <p className="text-lg text-center font-bold">FRIENDS AND EARN</p>
        </div>
        <div className="flex w-full justify-center items-center">
          <img src={Images.gift} alt="gift" className="w-28" />
        </div>
        <div className="flex flex-col w-full justify-center mt-2 items-center">
          <p className="font-semibold">Referral Bonus</p>
          <div className="flex w-full justify-center mt-2 items-center">
            <img src={Images.coin} className="w-10" alt="coint" />
            <p className="text-lg font-bold">
              {userDetail.user_profile?.referal_bonus ?? "0"} Points
            </p>
          </div>
        </div>
        <div className="flex flex-col w-full justify-center mt-2 items-center">
          <p className="font-semibold">Registration Bonus</p>
          <div className="flex w-full justify-center mt-2 items-center">
            <img src={Images.coin} className="w-10" alt="coint" />
            <p className="text-lg font-bold">
              {userDetail.user_profile?.registration_bonus ?? "0"} Points
            </p>
          </div>
        </div>
      </div>
      <div className="flex w-3/4 m-auto  border-dashed border-2 border-black rounded-md py-4 px-4 bg-yellow-500   justify-around mt-8 items-center">
        <div className="flex flex-col justify-center items-center">
          <p className="text-sm font-bold">Your referral code </p>
          <p className="text-2xl font-bold">
            {userDetail.user_profile.referral_code ?? "-"}
          </p>
        </div>
        <p
          onClick={() => {
            navigator.clipboard
              .writeText(userDetail.user_profile.referral_code ?? "-")
              .then((res) => {
                dispatchtoast("Copied to clipboard!");
              })
              .catch((err) => console.log(err));
          }}
          className="text-xs cursor-pointer font-bold"
        >
          Copy here
        </p>
      </div>
    </>
  );
};

export default Referrals;
