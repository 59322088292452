import React, { useState } from 'react'
import Carousal from './Carousal'
import ProductSuggestions from './ProductSuggestions'
import { useEffect } from 'react'
import { GetAllData } from '../../ApiServices/Apifun'

const Home = () => {
  const [banner, setBanner] = useState([]);

  const getalldata = () => {
    GetAllData()
      .then((res) => {
        if (res.status) {
          setBanner(res.data.bannerData);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getalldata();
  }, []);
  return (
    <>
    <Carousal banner={banner}/>
   <div className='px-8'>
   <ProductSuggestions banner={banner}/>
   </div>
    {/* <Footer/> */}
    </>
  )
}

export default Home