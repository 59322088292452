import React, { useEffect, useState } from "react";
import {
  DeleteShippingAddress,
  getShippingAddress,
  setDefaultShippingAddress,
} from "../../ApiServices/Apifun";
import { dispatchtoast } from "../../Utils";
import { CiCircleCheck, CiMobile2 } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import { Button, Spin } from "antd";
import AddAddressDrawer from "./AddAddressDrawer";
import { Images } from "../../ImageController/Index";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Addresses = () => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [addressDetail, setAddressDetail] = useState([]);
  const [openAdDrawer, setOpenAddDrawer] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const getAddress = () => {
    setLoading(true);
    getShippingAddress()
      .then((res) => {
        if (res.status) {
          if (res.data.length !== 0) {
            setAddressDetail(res.data);
            setSelected(res.data.find((i) => i.is_default === 1).id);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getAddress();
  }, []);
  const handleEdit = (data) => {
    setRecordId(data.id);
    setOpenAddDrawer(true);
  };
  const handleDelete = (data) => {
    setLoading(true);
    const payload = {
      record_id: [data.id],
    };
    DeleteShippingAddress(payload)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          getAddress();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleSetAddress = (data) => {
    setDefaultShippingAddress(data.id)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setSelected(data.id);
          getAddress();
          //   dispatch(setOpenAddressModal(false));
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <div className="flex justify-end items-center">
        <Button
          onClick={() => setOpenAddDrawer(true)}
          style={{ color: "white" }}
          className="bg-primary border-none hover:border-none outline-none hover:outline-none"
        >
          + Add Address
        </Button>
      </div>
      <Spin spinning={loading}>
        {addressDetail.length !== 0 ? (
          <div className="grid grid-cols-1 mt-4 md:grid-cols-2 lg:grid-cols-3 gap-4 place-items-center">
            {addressDetail.length !== 0 &&
              addressDetail.map((i, index) => (
                <div
                  key={index}
                  className={`cursor-pointer w-full h-full mb-4 ${
                    selected === i.id
                      ? "border-4 border-primary"
                      : "border border-gray-500"
                  } rounded-md relative px-8 py-3`}
                >
                  <div
                    
                    className="absolute  right-4"
                  >
                    {i.is_default === 0 ? (
                      <CiCircleCheck onClick={() => handleSetAddress(i)} size={20} />
                    ) : (
                      <FaCheckCircle color="#222" size={20} />
                    )}
                  </div>
                  <p className="font-semibold capitalize">
                    {i.addr_name}{" "}
                    <span className="border mx-4 border-green-500 text-green-500 rounded-full px-4">
                      {i.addr_title}
                    </span>
                  </p>
                  <div className="mt-4 text-gray-500 font-semibold capitalize text-[14px]">
                    <p>
                      {i.addr_address1}, {i.addr_address2}
                    </p>
                    <p>
                      {i.addr_city}, {i.addr_state_name}, {i.addr_country_name},{" "}
                      {i.addr_zip}
                    </p>
                  </div>
                  <div className="flex mt-4 justify-start items-center gap-2">
                    <CiMobile2 size={20} />
                    <p className="text-gray-500 capitalize font-thin text-[14px]">
                      {i.addr_phone}
                    </p>
                  </div>
                  <div className="flex justify-around gap-4 items-center mt-4">
                    <p
                      onClick={() => handleEdit(i)}
                      className=" text-center transition-all hover:shadow-2xl hover:border border-gray-300 font-semibold cursor-pointer p-2 shadow-gray-400 w-full"
                    >
                      Edit
                    </p>
                    <p
                      onClick={() => handleDelete(i)}
                      className={` text-center ${
                        i.is_default === 1 ? "" : "block"
                      } transition-all hover:shadow-2xl hover:border border-gray-300 font-semibold cursor-pointer p-2 shadow-gray-400 w-full`}
                    >
                      Remove
                    </p>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="flex flex-col cursor-pointer justify-center items-center">
            <LazyLoadImage onError={(err)=>{
                              err.target.src=Images.NoImage
                              err.target.alt="No Image"
                            }} src={Images.NoAddress} alt="no-address" className="w-64"/>
            <p className="text-center font-semibold text-xl font-heading">No Address Found</p>
          </div>
        )}
      </Spin>
      {openAdDrawer && (
        <AddAddressDrawer
          recordId={recordId}
          setRecordId={setRecordId}
          apiCall={getAddress}
          setOpenAddDrawer={setOpenAddDrawer}
        />
      )}
    </>
  );
};

export default Addresses;
