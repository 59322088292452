import { Button } from "antd";
import React from "react";

const Commonbutton = ({ text,onClick=()=>{}, loading = false, htmltype = "button",className="bg-primary text-white focus:outline-none  hover:border-none hover:outline-none" }) => {
  return (
    <Button onClick={onClick} style={{color:"white"}} className={className} loading={loading} htmlType={htmltype}>
      {text}
    </Button>
  );
};

export default Commonbutton;
