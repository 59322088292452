import { Spin } from "antd";
import React, { Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";

const Dynamic = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  return (
    <>
      <Suspense fallback={<Spin spinning={true}></Spin>}>
      <div className="md:container md:mx-auto mx-4 mt-20 select-none">
        <div className="flex dynamicbg justify-center items-center  h-52">
          <p className="text-white animate-pulse p-5 bg-[#ffffff3b] backdrop-blur-xl text-4xl uppercase font-heading">
            {location?.state?.page_name}
          </p>
        </div>
        <div className="mt-2">
            {location.state?.page_data}
        </div>
      </div>
      </Suspense>
    </>
  );
};

export default Dynamic;
