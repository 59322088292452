import React, { useEffect, useState } from "react";
import { Images } from "../../ImageController/Index";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createImageUrl, getDecryptedLocalStorage, getSymbol } from "../../Utils";

const OrderSuccess = () => {
  const location = useLocation();
  const navigate=useNavigate()
  const { items,discountedPrice ,orderId} = location.state || {};
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if(!location.state){
        navigate(-1)
    }
    const total = items?.reduce((a, b) => {
      a = a + (getDecryptedLocalStorage('country')==="IN"? b.total_qty_price_inr:b.total_qty_price_usd);
      return a;
    }, 0);
    setTotalAmount(total);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items,location]);
  return (
    <>
      <div className=" ">
        <div className="flex flex-col justify-center bg-green-700 h-[250px] items-center">
          <LazyLoadImage
          loading="lazy"
          onError={(err)=>{
            err.target.src=Images.NoImage
            err.target.alt="No Image"
          }}
            src={Images.SuccessIcon}
            className="w-28 animate-pulse"
            alt="success"
          />
          <p className="text-xl mt-4 text-white font-bold font-heading">
            Order Successfull
          </p>
          <p className="text-xl mt-4 text-white font-bold font-heading">
            Order Id: {orderId}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:container mx-4 md:mx-auto mt-8 place-items-start gap-y-2 gap-x-4">
        <div className="col-span-2 w-full">
          <p className="text-lg font-heading font-semibold font-Roboto my-2 ">Ordered Item List:- </p>
          <div className=" p-2 shadow-sm rounded-sm shadow-gray-300">
            {items?.length !== 0 ? (
              items?.map((i, index) => {
                const image= i.productOptionDetail.map((i)=>{
                  return {...i,option_images:typeof i.option_images=="string"?JSON.parse(i.option_images):i.option_images}
                })
                return (
                  <>
                    <div className="grid relative grid-cols-3 mt-2 font-semibold place-items-center">
                      <div className="col-span-2 w-full">
                        <div className="flex w-full justify-center gap-8 items-center">
                          <LazyLoadImage
                          onError={(err)=>{
                            err.target.src=Images.NoImage
                            err.target.alt="No Image"
                          }}
                              src={createImageUrl(
                                image.length !== 0
                                  ? image.filter(
                                      (i) => i.option_name === "color"
                                    )[0]?.option_images[0]
                                  : i.defaultImageDetail[0].image
                              )}
                            // src={Images.Model}
                            alt="itemImage"
                            crossOrigin="anonymous"
                            className="w-24 m-auto rounded-sm"
                          />
                          <div className="w-full">
                            <p
                              className="cursor-pointer"
                              // onClick={() =>
                              //   navigate("/productDesc", {
                              //     state: { id: i.product_id },
                              //   })
                              // }
                            >
                              {" "}
                              {i.product_model}
                            </p>
                            <p className="text-xs"> {i.product_name}</p>
                            {i.productOptionDetail.map((i, index) => (
                              <p key={index} className="text-xs">
                                {i.option_name}: {i.option_value_name}
                              </p>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div>
                        <p> {getSymbol()}{" "}
                              {getDecryptedLocalStorage("country") === "IN"
                                ? i.total_qty_price_inr
                                : i.total_qty_price_usd}</p>
                      </div>
                    </div>
                    {index === items.length ? (
                      <div className="w-full border border-gray-200 mt-4 h-[1px]"></div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <div className="flex  justify-center items-center">
                <div>
                  <LazyLoadImage
                  onError={(err)=>{
                    err.target.src=Images.NoImage
                    err.target.alt="No Image"
                  }}
                    src={Images.EmptyCart}
                    alt="empty-cart"
                    className="w-36"
                  />
                  <p
                    //   onClick={() => navigate("/")}
                    className="text-center bg-primary p-3 rounded-sm transition-all hover:scale-x-105 cursor-pointer font-semibold text-white "
                  >
                    Explore Products
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full my-2">
          <div className=" p-4 bg-[#f8f8f8]">
            <p className="font-semibold text-center  text-3xl font-heading  ">Cart Totals</p>
            <div className="flex mt-4 justify-between items-center">
                  <p className="text-sm font-bold">Subtotal</p>
                  <p className="font-bold font-mono">₹ {Number(totalAmount)}</p>
                </div>
                <div className="flex mt-4 justify-between items-center">
                  <p className="text-sm font-bold">Discount</p>
                  <p className="font-bold font-mono">
                    {" "}
                    -{Number(discountedPrice)}
                  </p>
                </div>
                <div className="flex mt-4 justify-between items-center">
                  <p className="text-sm font-bold">Total</p>
                  <p className="font-bold font-mono">
                    ₹ {Number(totalAmount) - Number(discountedPrice)}
                  </p>
                </div>
            <div className="flex flex-wrap gap-y-2 w-full mt-8 gap-x-4 justify-between items-center">
              <Button
              onClick={()=>navigate("/")}
                  style={{ color: "white" }}
                  className="w-full bg-black rounded-sm font-bold text-sm text-center h-10 text-white border-none hover:border-none outline-none hover:outline-none"
                >
                  Continue Shopping
                </Button>
                {/* <Button
                  style={{ color: "white" }}
                  className="w-full bg-textColor rounded-sm font-bold text-sm text-center h-10 text-white border-none hover:border-none outline-none hover:outline-none"
                >
                  Leave Feedback
                </Button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
