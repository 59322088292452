import { Form, Input, Modal, Rate } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Commonbutton from "../CommmonComponents/Commonbutton";
import { AddReview, EditReview } from "../ApiServices/Apifun";
import { dispatchtoast } from "../Utils";

const WriteReviewModal = ({ open, setOpen, productId = null, data = null,apiCall=()=>{} }) => {
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(null);
  const formref = useRef();
  const [form] = Form.useForm();
  useEffect(() => {
    if (data) {
      formref.current.setFieldsValue({
        review_title: data.review_title,
        review_text: data.review_text,
      });
      setRating(data.rating);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSubmit = (val) => {
    setLoading(true);
    if (data) {
      EditReview({
        product_id:data.product_id,
        record_id: data.record_id,
        review_title: val.review_title,
        review_text: val.review_text,
        review_rating: rating,
      })
        .then((res) => {
          if (res.status) {
            setOpen(false);
            apiCall()
            dispatchtoast(res.message);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    } else {
      AddReview({
        product_id: productId,
        review_title: val.review_title,
        review_text: val.review_text,
        review_rating: rating,
      })
        .then((res) => {
          if (res.status) {
            setOpen(false);
            dispatchtoast(res.message);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <Modal
      width={500}
      footer={null}
      open={open}
      onCancel={() => setOpen(false)}
    >
      <div className="grid grid-cols-1 1 place-items-center mt-8">
        {/* <div className="hidden sm:block">
          <LazyLoadImage
            loading="lazy"
            src={Images.WriteReview}
            alt="loginImage"
          />
        </div> */}
        <div className="border w-full border-[#DBE7FF] shadow-[0_6px_30px_0px_rgba(0,0,0,0.08)] p-4  ">
          <p className="text-black text-xl font-semibold font-Roboto">
            Write a review{" "}
          </p>
          <p className="text-[#8D8D8D] text-xs mt-2">
            Write a review and give feedback about product
          </p>
          <div className="mt-4">
            <p>Rating</p>
            <Rate onChange={(e) => setRating(e)} value={rating} allowHalf />
          </div>
          <div className="">
            <Form
              ref={formref}
              form={form}
              onFinishFailed={(err) => console.log(err)}
              onFinish={(val) =>
                rating
                  ? handleSubmit(val)
                  : dispatchtoast("Please add rating also!", true)
              }
            >
              <Form.Item
                className="p-0"
                label={<p className="p-0 m-0">Title</p>}
                labelCol={{ span: 24 }}
                name={"review_title"}
                rules={[
                  {
                    required: true,
                    message: "Write A Title!",
                  },
                  {
                    min: 3,
                    message: "Minimum 3 charecters are required",
                  },
                ]}
              >
                <Input
                  showCount={true}
                  maxLength={50}
                  name={"review_title"}
                  placeholder={"Write A Title!"}
                />
              </Form.Item>
              <Form.Item
                label={<p className="p-0 m-0">Comment</p>}
                labelCol={{ span: 24 }}
                name={"review_text"}
                rules={[
                  {
                    required: true,
                    message: "Write Some Review!",
                  },
                  {
                    min: 3,
                    message: "Minimum 3 charecters are required",
                  },
                ]}
              >
                <Input.TextArea
                  className="text-xs border w-full bg-inputBg  border-[#99A9FF]  h-9 px-2"
                  showCount={true}
                  name={"review_text"}
                  placeholder={"Write Some Review!"}
                />
              </Form.Item>
              <Form.Item>
                <div className="flex mt-4 justify-center items-center">
                  <Commonbutton
                    loading={loading}
                    htmltype="submit"
                    className="bg-black rounded-none border-none text-white focus:outline-none w-[227px] hover:border-none hover:outline-none"
                    text={"Submit"}
                  />
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WriteReviewModal;
