import React, { useEffect, useState } from "react";
import { getWishlist } from "../../ApiServices/Apifun";
import { Skeleton, Spin } from "antd";
import { Images } from "../../ImageController/Index";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createImageUrl, handleRemoveFromFavourite } from "../../Utils";
import { useSelector } from "react-redux";
import CommonPagination from "../../CommmonComponents/CommonPagination";
import QuickOverView from "../Home/QuickOverView";
import CommonCard from "../../CommmonComponents/CommonCard";

const Favourites = () => {
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [favouriteList, setFavouriteList] = useState([]);
  const [current, setCurrent] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [openQuick, setOpenQuick] = useState(false);
  const [productId, setProductId] = useState(null);
  // const [limit, setLimit] = useState(10);
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const [loading, setLoading] = useState(false);
  const getWishListData = () => {
    setLoading(true);
    // setShowSkeleton(true);
    getWishlist(`?page=${current}&limit=${10}`)
      .then((res) => {
        if (res.status) {
          setCurrent(res.currentPage);
          setTotalPages(res.totalRecords);
          setFavouriteList(res.data);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setTimeout(() => {
          setShowSkeleton(false);
          setLoading(false);
        }, 300);
      });
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [current]);
  useEffect(() => {
    if (isLoggedIn) {
      getWishListData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  return (
    <>
      <Spin spinning={loading}>
        <div className="md:container md:mx-auto mx-4  select-none">
          <div className="flex justify-center items-center">
            <p className="text-3xl sectionHeading relative md:text-5xl text-textColor font-heading  rounded-sm flex justify-center items-center  cursor-pointer ">
              Favourites
            </p>
          </div>
          {showSkeleton ? (
            <div className="grid grid-cols-1 mt-4 sm:grid-cols-2 md:grid-cols-5 gap-4 place-items-center">
              {[1, 2, 3, 4, 5, 6, 4, 4].map((i,index) => (
                <div key={index} className="  h-[200px]   w-full">
                  <Skeleton.Button
                    active={true}
                    size={200}
                    shape={"default"}
                    block={false}
                  />
                </div>
              ))}
            </div>
          ) : favouriteList.length !== 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 mt-4 gap-y-0 gap-x-5 place-items-center">
              {favouriteList.map((i, index) => {
                // const image = JSON.parse(i.default_image);
                const image =
                  typeof i.default_image == "string"
                    ? JSON.parse(i.default_image)
                    : i.default_image;

                return (
                  <>
                    <CommonCard
                    key={index}
                      from="fav"
                      index={index}
                      image={
                        image.length !== 0
                          ? createImageUrl(image[0])
                          : Images.NoImage
                      }
                      handleFav={() =>
                        handleRemoveFromFavourite(i.product_id, getWishListData)
                      }
                      data={i}
                      setslug={setProductId}
                      setopen={setOpenQuick}
                    />
                  </>
                );
              })}
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center">
              <div className=" rounded-full">
                <LazyLoadImage
                  src={Images.NoFavourite}
                  className="p-8 "
                  alt="no favourite"
                />
              </div>
              <p className="text-textColor font-bold">No favourite items</p>
              <NavLink to="/">
                <p className="text-textColor font-thin text-xs underline cursor-pointer">
                  Explore Product
                </p>
              </NavLink>
            </div>
          )}
          {favouriteList.length !== 0 && (
            <div className="flex justify-end mr-8 items-center mb-8">
              <CommonPagination
                defaultPageSize={10}
                current={current}
                pageSize={10}
                setCurrent={setCurrent}
                totalPages={totalPages}
              />
            </div>
          )}
        </div>
      </Spin>
      {openQuick && (
        <QuickOverView
          open={openQuick}
          setopen={setOpenQuick}
          productId={productId}
        />
      )}
    </>
  );
};

export default Favourites;
