import { Drawer } from "antd";
import React from "react";
import AddressField from "../../CommmonComponents/AddressField";
const AddAddressDrawer = ({setRecordId, setOpenAddDrawer, recordId, apiCall }) => {
  return (
    <Drawer
      title={recordId?"Edit Address":"Add Address"}
      onClose={() => {
        setOpenAddDrawer(false)
      setRecordId(null)
      }}
      width={450}
      open={true}
    >
      <AddressField
        apiCall={apiCall}
        setShowAddForm={setOpenAddDrawer}
        recordId={recordId}
      />
    </Drawer>
  );
};

export default AddAddressDrawer;
