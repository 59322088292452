import { Button, Modal } from "antd";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../ImageController/Index";

const ConfirmationModal = ({
  open,
  setOpen,
  fun,
  title = "Are You Sure?",
  desc = "",
}) => {
  return (
    <>
      <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
        <div className="flex flex-col justify-center items-center">
          <LazyLoadImage className="w-20" src={Images.Pending} alt="sure" />
          <p className="font-semibold text-xl">{title}</p>
          <p className="font-semibold text-sm">{desc}</p>
          <div className="flex justify-center gap-4 mt-4 items-center">
            <Button>Cancel</Button>
            <Button onClick={fun}
              style={{ color: "white" }}
              className="bg-black rounded-none w-20 border-none hover:border-none"
            >
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
