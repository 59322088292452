import { Input } from "antd";
import React from "react";

const CommonInput = ({
  placeholder,
  suffix = null,
  showCount,
  name,
  value,
  maxlength = 500,
  onInput = () => {},
  onChange = () => {},
  disabled = false,
  className = "text-xs border w-full rounded-none bg-inputBg border   h-9 px-2",
}) => {
  return (
    <Input
      placeholder={placeholder}
      autoComplete="off"
      showCount={showCount}
      // value={value}
      name={name}
      suffix={suffix}
      disabled={disabled}
      maxLength={maxlength}
      onInput={onInput}
      onChange={onChange}
      className={className}
    />
  );
};

export default CommonInput;
