import React, { useEffect, useRef, useState } from "react";
import { MailOutlined, ContactsFilled, PhoneOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useSelector } from "react-redux";
import { ContactSend } from "../../ApiServices/Apifun";
import { dispatchtoast, emailPattern, mobilePattern } from "../../Utils";
const Contactus = () => {
  const getDetail = useSelector((state) => state.common.pagesDetail);
  //   const [getDetail, setDetail] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const formref = useRef();
  const [form] = Form.useForm();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  });
  const handleSubmit = (val) => {
    setBtnLoading(true);
    ContactSend(val)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          form.resetFields();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="md:container md:mx-auto mx-4 mt-20  ">
        <div className="flex justify-center items-center dynamicbg h-52">
          <p className="text-white animate-pulse p-5 bg-[#ffffff3b] backdrop-blur-xl text-4xl uppercase font-heading">
            Contact Us
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 h-auto gap-2  place-items-center  mt-4">
          <div className=" border bg-[#F9F9F9] h-full w-full p-4 ">
            <div className="Reachus leading-10">
              <p className="font-bold  text-black text-xl">Reach Us</p>
              {/* <div className="flex justify-start items-center gap-4">
                  <p>
                    <UserOutlined className="p-2 rounded-full bg-gray-400 text-white" />
                  </p>
                  <p>Puneet Agarwal</p>
                </div> */}
              <div className="flex mt-5 justify-start items-center gap-4">
                <p>
                  <MailOutlined className="p-2 rounded-full bg-gray-400 text-white" />
                </p>
                <p className="capitalize font-semibold text-sm">
                  {getDetail?.contact_page?.email}
                </p>
              </div>
              <div className="flex justify-start items-center gap-4">
                <p>
                  <PhoneOutlined className="p-2 rounded-full bg-gray-400 text-white" />
                </p>
                <p className="capitalize font-semibold text-sm">
                  {getDetail?.contact_page?.contact}
                </p>
              </div>
              <div className="flex justify-start items-center gap-4">
                <p>
                  <ContactsFilled className="p-2 rounded-full bg-gray-400 text-white" />
                </p>
                <p className="capitalize font-semibold text-sm">
                  {getDetail?.contact_page?.address}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full border p-4 ">
            <Form
              ref={formref}
              form={form}
              onFinish={(val) => handleSubmit(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <div className="grid grid-cols-2 mt-5 gap-4 place-items-center w-full">
                <Form.Item
                  name={"fname"}
                  rules={[
                    {
                      required: true,
                      message: "Enter First Name",
                    },
                  ]}
                  label="First Name"
                  labelCol={{ span: 24 }}
                  className="w-full"
                >
                  <Input
                    maxLength={40}
                    className="border-b-2 mt-2 w-full h-10  border-primary text-xs"
                    placeholder="Enter Your Name"
                  />
                </Form.Item>
                <Form.Item
                  name={"lname"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Last Name",
                    },
                  ]}
                  label="Last Name"
                  labelCol={{ span: 24 }}
                  className="w-full"
                >
                  <Input
                    maxLength={20}
                    className="border-b-2 mt-2 w-full h-10  border-primary text-xs"
                    placeholder="Enter Your Name"
                  />
                </Form.Item>{" "}
                <Form.Item
                  name={"mobile"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Mobile",
                    },
                    {
                      pattern: mobilePattern,
                      message: "Enter valid mobile",
                    },
                  ]}
                  label="Mobile"
                  labelCol={{ span: 24 }}
                  className="w-full"
                >
                  <Input
                    maxLength={10}
                    onInput={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                    className="border-b-2 mt-2 w-full h-10  border-primary text-xs"
                    placeholder="Enter Mobile"
                  />
                </Form.Item>{" "}
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Enter Email",
                    },
                    {
                      pattern: emailPattern,
                      message: "Enter valid email",
                    },
                  ]}
                  label="Email"
                  labelCol={{ span: 24 }}
                  className="w-full"
                >
                  <Input
                    className="border-b-2 mt-2 w-full h-10  border-primary text-xs"
                    placeholder="Enter Email"
                  />
                </Form.Item>
              </div>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Enter Message",
                  },
                ]}
                name={"message"}
                label="Message"
                labelCol={{ span: 24 }}
                className="w-full"
              >
                <Input.TextArea
                  maxLength={150}
                  showCount={true}
                  rows={4}
                  className="border-b-2 mt-2 w-full h-10  border-primary text-xs"
                  placeholder="Enter Your Message"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={btnLoading}
                  htmlType="submit"
                  className="w-full rounded-none h-12 mt-4 bg-primary outline-none border-none hover:bg-primaryHover"
                  style={{ color: "white" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
