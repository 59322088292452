import { HashRouter, useRoutes } from "react-router-dom";
import "./App.css";
import { PrivateRoutes } from "./Routes/CommerceRoutes";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-slideshow-image/dist/styles.css';
import { ToastContainerMsg } from "./Utils";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const CommerceRouting = [...PrivateRoutes()];
  const CommerceRoutConfig = () => useRoutes(CommerceRouting);
  return (
    <>
    <HashRouter>
      <CommerceRoutConfig />
    </HashRouter>
    <ToastContainerMsg/>
    </>
  );
}

export default App;
