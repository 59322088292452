import { Drawer,  Spin } from "antd";
import React, { useEffect, useState } from "react";
import AddressField from "./AddressField";
import { CiMobile2 } from "react-icons/ci";
import { IoHomeOutline } from "react-icons/io5";
import {
  DeleteShippingAddress,
  getShippingAddress,
  setDefaultShippingAddress,
} from "../ApiServices/Apifun";
import { dispatchtoast } from "../Utils";
import { useDispatch } from "react-redux";
import { setOpenAddressModal } from "../Redux/commonSlice";
import { CiCircleCheck } from "react-icons/ci";
import { FaCheckCircle } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
// import getMAC from "getmac";

const AddressDrawer = () => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [addressDetail, setAddressDetail] = useState([]);
  const [showAddForm, setShowAddForm] = useState(false);
  const [recordId, setRecordId] = useState(null);

  const dispatch = useDispatch();
  const getAddress = () => {
    setLoading(true);
    getShippingAddress()
      .then((res) => {
        if (res.status) {
          if (res.data.length !== 0) {
            setAddressDetail(res.data);
            setSelected(res.data.find((i) => i.is_default === 1).id);
          } else {
            setShowAddForm(true);
          }
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    getAddress();
  }, [showAddForm]);
  const handleEdit = (data) => {
    setRecordId(data.id);
    setShowAddForm(true);
  };
  const handleDelete = (data) => {
    setLoading(true);
    const payload = {
      record_id: [data.id],
    };
    DeleteShippingAddress(payload)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          getAddress();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleSetAddress = (data) => {
    setDefaultShippingAddress(data.id)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setSelected(data.id);
          getAddress();
          dispatch(setOpenAddressModal(false));
        }
      })
      .catch((err) => console.log(err));
  };
  // console.log(getMAC())

  return (
    <>
      <Drawer
        closeIcon={
          showAddForm &&recordId ? (
            <IoArrowBack onClick={() => setShowAddForm(false)} />
          ) : (
            <IoMdClose onClick={() => dispatch(setOpenAddressModal(false))} />
          )
        }
        title="Add Address"
        // onClose={() => dispatch(setOpenAddressModal(false))}
        width={450}
        open={true}
      >
        {showAddForm ? (
          <AddressField recordId={recordId} setShowAddForm={setShowAddForm} />
        ) : (
          <Spin spinning={loading}>
            <div>
              {addressDetail.length !== 0 &&
                addressDetail.map((i, index) => (
                  <div
                    key={index}
                    className={`cursor-pointer mb-4 ${
                      selected === i.id
                        ? "border-4 border-textColor"
                        : "border border-gray-500"
                    } rounded-md relative px-8 py-3`}
                  >
                    <div
                      onClick={() => handleSetAddress(i)}
                      className="absolute  right-4"
                    >
                      {i.is_default === 0 ? (
                        <CiCircleCheck size={20} />
                      ) : (
                        <FaCheckCircle color="#222" size={20} />
                      )}
                    </div>
                    <p className="font-semibold capitalize">
                      {i.addr_name}{" "}
                      <span className="border mx-4 border-green-500 text-green-500 rounded-full px-4">
                        {i.addr_title}
                      </span>
                    </p>
                    <div className="mt-4 text-gray-500 font-semibold capitalize text-[14px]">
                      <p>
                        {i.addr_address1}, {i.addr_address2}
                      </p>
                      <p>
                        {i.addr_city}, {i.addr_state_name},{" "}
                        {i.addr_country_name}, {i.addr_zip}
                      </p>
                    </div>
                    <div className="flex mt-4 justify-start items-center gap-2">
                      <CiMobile2 size={20} />
                      <p className="text-gray-500 font-semibold text-[14px]">
                        {i.addr_phone}
                      </p>
                    </div>
                    <div className="flex justify-around gap-4 items-center mt-4">
                      <p
                        onClick={() => handleEdit(i)}
                        className=" text-center transition-all hover:shadow-2xl hover:border border-gray-300 font-semibold cursor-pointer p-2 shadow-gray-400 w-full"
                      >
                        Edit
                      </p>
                      <p
                        onClick={() => handleDelete(i)}
                        className={` text-center ${
                          i.is_default === 1 ? "" : "block"
                        } transition-all hover:shadow-2xl hover:border border-gray-300 font-semibold cursor-pointer p-2 shadow-gray-400 w-full`}
                      >
                        Remove
                      </p>
                    </div>
                  </div>
                ))}
              <div
                onClick={() => setShowAddForm(true)}
                className="flex justify-center items-center gap-2 border-2 transition-all hover:border-blue-600 hover:shadow-lg shadow-gray-500 hover:text-blue-600 border-dashed border-gray-300 py-5 cursor-pointer font-semibold "
              >
                <IoHomeOutline />
                <p>Add new address</p>
              </div>
            </div>
          </Spin>
        )}
      </Drawer>
    </>
  );
};

export default AddressDrawer;
