import React from "react";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createImageUrl } from "../../Utils";

const Carousal = ({banner}) => {

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true, // Corrected here
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  return (
    <>
      <div className="">
        <div className="grid  grid-cols-4 h-1/2 w-full md:container md:mx-auto mx-4 mt-20 select-none place-items-start gap-x-2 md:gap-x-8">
            <div className="col-span-4   w-full h-full">
            <Slider {...settings}>
          {banner && banner.length !== 0 ? (
            banner.filter(i=>i.section_id===null|| i.section_id===undefined).map((i,index) => {
              return (
                <div key={index}>
                  <LazyLoadImage
                  crossOrigin="anonymous"
                    // onError={(err) => {
                    //   err.target.src = Images.BannerImage;
                    //   err.target.alt = "No Image";
                    // }}
                    alt="banner"
                    loading="lazy"
                    src={createImageUrl(i.image_name, "banner")}
                    className="w-full max-h-[500px] object-cover"
                  />
                </div>
              );
            })
          ) : (
            <div className="flex justify-center m-auto items-center">
              <p>Loading....</p>
            </div>
          )}
        </Slider>
            </div>
            {/* <div className=" w-full flex-col justify-center items-center">
              <LazyLoadImage src={Images.pc} alt="productbanner" className="w-9/12 mb-8" />
              <LazyLoadImage src={Images.perfume} alt="productbanner" className="w-9/12" />
            </div> */}
       
        </div>
       
      </div>
    </>
  );
};

export default Carousal;
