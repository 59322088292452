import React, { useEffect, useState } from "react";
import { Button, Checkbox, Collapse, Radio, Rate } from "antd";
import { useDispatch, useSelector } from "react-redux";
import BrandModal from "./BrandModal";
import { getDecryptedLocalStorage, priceScale, priceScaleUSD } from "../../Utils";
import { FaChevronRight } from "react-icons/fa";
import { clearFilter, setFilters } from "../../Redux/userSlice";
import { useLocation } from "react-router-dom";

const Filtercomponent = ({
  setOpen=()=>{},
  data,
  setSelectedId,
  selectedId,
  brands,
}) => {
  const { Panel } = Collapse;
  const location = useLocation();
  const dispatch = useDispatch();
  const [filters, setFilterdata] = useState({
    price: [],
    brandList: [],
    rating: null,
    category: null,
  });
  const getFilters = useSelector((state) => state.user.filters);
  const [openBrandModal, setOpenBrandModal] = useState(false);
  const [customError, setCustomError] = useState("");
  const [customPrice, setCustomPrice] = useState({
    min: null,
    max: null,
  });
  useEffect(() => {
    setFilterdata(getFilters);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const renderCollapsePanels = (category) => {
    return category?.map((item) => {
      if (item.child_category.length >= 0) {
        return (
          <Panel
            showArrow={item.child_category.length > 0 ? true : false}
            style={{
              background:
                "linear-gradient(90deg, rgba(41, 66, 203, 0.05) 0%, rgba(39, 61, 177, 0.05) 100%)",
              borderRadius: "1px",
              marginBottom: "0px",
            }}
            collapsible="icon"
            key={item.id}
            header={
              <p
                onClick={() => setSelectedId(item.id)}
                className={`font-semibold ${
                  selectedId === item.id ? "bg-gray-200 text-primary p-2" : ""
                }  cursor-pointer capitalize`}
              >
                {item.name}
              </p>
            }
            className="capitalize"
          >
            <Collapse
              rootClassName="bg-gray-100"
              collapsible="header"
              bordered={false}
            >
              {renderCollapsePanels(item.child_category)}
            </Collapse>
          </Panel>
        );
      }
    });
  };
  const checkcustomPrice = () => {
    if (Number(customPrice.min) > Number(customPrice.max)) {
      setCustomError("Min Price cannot be greater than Max Price");
      return false;
    } else if (!customPrice.min && !customPrice.max) {
      setCustomError("Please enter your price range");
      return false;
    } else {
      return true;
    }
  };
  return (
    <>
      <div className="col-span-1 border border-gray-300   categorytree  rounded-md p-4 w-full ">
        <p className=" font-heading font-bold text-xl mb-2">Refine By</p>
        <Collapse
          ghost={true}
          bordered={false}
          accordion
          className="individualCategory"
        >
          {renderCollapsePanels(data)}
          {data.length <= 0 && (
            <Collapse.Panel
              showArrow={false}
              header={
                <p className="font-semibold text-center">
                  No category available
                </p>
              }
            ></Collapse.Panel>
          )}
        </Collapse>
        <div
          style={{
            background:
              "linear-gradient(90deg, rgba(41, 66, 203, 0.05) 0%, rgba(39, 61, 177, 0.05) 100%)",
          }}
          className="rounded-sm mt-3 p-4  w-full"
        >
          <div className="mt-2 font-lato">
            <p className="text-textColor text-[18px] font-normal">Price</p>
            {getDecryptedLocalStorage("country")==="IN"?
            priceScale.map((i,index) => (
              <div key={index}
                className={` ${
                  customPrice.min ? "hidden" : "flex"
                } text-textColor my-2 justify-between items-center`}
              >
                <Checkbox
                  checked={filters.price.some(
                    (item) => item.min === i.value.min
                  )}
                  onChange={() => {
                    // debugger
                    let data = { ...filters };
                    if (data.price.find((item) => item.min === i.value.min)) {
                      data.price = data.price.filter(
                        (item) => item.min !== i.value.min
                      );
                    } else {
                      data.price = [...data.price, i.value];
                    }
                    setFilterdata(data);
                  }}
                >
                  <p className=" text-xs">{i.label}</p>
                </Checkbox>
              </div>
            )): priceScaleUSD.map((i,index) => (
              <div key={index}
                className={` ${
                  customPrice.min ? "hidden" : "flex"
                } text-textColor my-2 justify-between items-center`}
              >
                <Checkbox
                  checked={filters.price.some(
                    (item) => item.min === i.value.min
                  )}
                  onChange={() => {
                    // debugger
                    let data = { ...filters };
                    if (data.price.find((item) => item.min === i.value.min)) {
                      data.price = data.price.filter(
                        (item) => item.min !== i.value.min
                      );
                    } else {
                      data.price = [...data.price, i.value];
                    }
                    setFilterdata(data);
                  }}
                >
                  <p className=" text-xs">{i.label}</p>
                </Checkbox>
              </div>
            ))}
            <hr />
            <div className="mt-2">
              <p className="text-xs">
                Enter Price Range{" "}
                <span
                  onClick={() => {
                    setCustomPrice({ min: null, max: null });
                    let data = { ...getFilters };
                    data.price = [];
                    dispatch(setFilters(data));
                  }}
                  className="pl-4 cursor-pointer"
                >
                  Clear
                </span>
              </p>
              <div className="flex justify-around mt-2 items-center">
                <input
                  value={customPrice.min}
                  onChange={(e) => {
                    setCustomPrice({ ...customPrice, min: e.target.value });
                    setCustomError("");
                  }}
                  onInput={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    e.target.value = value;
                  }}
                  placeholder="Min"
                  className="w-14 text-xs border-none outline-none p-2"
                />
                <p className="text-gray-400">-</p>
                <input
                  value={customPrice.max}
                  onChange={(e) => {
                    setCustomPrice({ ...customPrice, max: e.target.value });
                    setCustomError("");
                  }}
                  onInput={(e) => {
                    const value = e.target.value.replace(/\D/g, "");
                    e.target.value = value;
                  }}
                  placeholder="Max"
                  className="w-14 text-xs border-none outline-none p-2"
                />
                <div
                  onClick={() => {
                    if (checkcustomPrice()) {
                      let data = { ...getFilters };
                      data.price = [
                        {
                          min: Number(customPrice.min),
                          max: Number(customPrice.max),
                        },
                      ];
                      dispatch(setFilters(data));
                    }
                  }}
                  className="border cursor-pointer border-primary rounded-full p-2"
                >
                  <FaChevronRight color="#91b7ce" size={10} />
                </div>
              </div>
              <p className="text-sm text-red-600">{customError}</p>
            </div>
            <hr className="mt-2" />
          </div>
          <div className="mt-4 font-lato">
            <p className="text-textColor  text-[18px] font-normal">Brands</p>
            {brands.length>0? brands.slice(0, 4).map((i,index) => (
              <div key={index} className="flex text-textColor my-2 justify-between items-center">
                <Checkbox
                  onChange={(e) => {
                    let data = { ...filters };
                    if (data.brandList.includes(e.target.value)) {
                      data.brandList = data.brandList.filter(
                        (i, index) => i !== e.target.value
                      );
                    } else {
                      data.brandList = [...data.brandList, e.target.value];
                    }
                    setFilterdata(data);
                  }}
                  value={i.id}
                  checked={filters.brandList.includes(i.id)}
                >
                  <p className=" text-xs">{i.name}</p>
                </Checkbox>
              </div>
            )):<p className="text-xs text-center font-semibold mt-1">No Brand Added</p>}
            {brands.length>4 && <p
              className="text-xs font-semibold cursor-pointer"
              onClick={() => setOpenBrandModal(true)}
            >
              More
            </p>}
          </div>
          <hr className="mt-2" />
          <div className="mt-4 font-lato">
            <p className="text-textColor  text-[18px] font-normal">Rating</p>
            {[1, 2, 3, 4].map((i,index) => (
              <div key={index} className="flex my-2 w-full justify-between items-center">
                <Radio checked={filters.rating===i} value={i} onChange={(e)=>{
                  let data={...filters}
                  data.rating=e.target.value
                  setFilterdata(data)
                }}>
                  <div className="flex justify-center gap-2 items-center">
                    <Rate
                      style={{ fontSize: "12px" }}
                      count={i}
                      defaultValue={i}
                    />
                    <p className="font-lato text-xs font-medium">{i} & above</p>
                  </div>
                </Radio>
              </div>
            ))}
          </div>
          <Button
            onClick={() => {
              dispatch(setFilters(filters));
              setOpen(false)
            }}
            style={{ color: "white" }}
            className="w-full mt-1 rounded-none bg-primary hover:border-none border-none"
          >
            Apply Filter
          </Button>
          <Button
            onClick={() => {
              dispatch(
                clearFilter({
                  price: [],
                  rating:null,
                  brandList: [],
                  category: null,
                })
              );
              setFilterdata({
                price: [],
                rating: null,
                brandList: [],
                category: null,
              });
              setOpen(false)

            }}
            style={{ color: "white" }}
            className="w-full mt-1 rounded-none bg-black hover:border-none border-none"
          >
            Cancel Filter
          </Button>
        </div>
      </div>
      {openBrandModal && (
        <BrandModal
          filters={filters}
          setFilterdata={setFilterdata}
          open={openBrandModal}
          setOpen={setOpenBrandModal}
          allBrands={brands}
        />
      )}
    </>
  );
};

export default Filtercomponent;
