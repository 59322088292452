import { FormDataPost, Get, Post, getAPIUrl } from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userRegister = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Register, param);
  return Post(url, data, false);
};
export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};
export const updateUserProfile = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateProfile, param);
  return Post(url, data);
};
export const updateUserProfileImage = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateProfileImage, param);
  return FormDataPost(url, data);
};
// export const ResendOtp = (data, param = "") => {
//   const url = getAPIUrl(Authendpoint.resendOtp, param);
//   return Post(url, data, false);
// };
// export const walletBal = (param = "") => {
//   const url = getAPIUrl(Globalendpoints.walletBalance, param);
//   return Get(url);
// };
export const GetStates = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getStates, param);
  return Post(url);
};
export const GetCountry = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getCountry, param);
  return Get(url);
};
export const GetUserDetail = (param = "") => {
  const url = getAPIUrl(Globalendpoints.userDetail, param);
  return Get(url);
};
export const GetAllData = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getAllData, param);
  return Get(url);
};
export const GetDataBySectionId = (param = "") => {
  const url = getAPIUrl(Globalendpoints.bySectionId, param);
  return Get(url);
};
export const GetProdDetail = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getProdDetail, param);
  return Get(url);
};

export const AddToCart = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addToCart, param);
  return Post(url, data);
};
export const GetCartList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getCartList, param);
  return Get(url);
};
export const UpdateQuantity = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.updateQuantity, param);
  return Post(url, data);
};
export const DeleteCart = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.deleteCart, param);
  return Post(url, data);
};
export const CheckProductAvailability = (param = "") => {
  const url = getAPIUrl(Globalendpoints.checkProductAvailability, param);
  return Get(url);
};
export const AddShippingAddress = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addShippingAddress, param);
  return Post(url, data);
};
export const EditShippingAddress = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.editShippingAddress, param);
  return Post(url, data);
};
export const DeleteShippingAddress = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.deleteShippingAddress, param);
  return Post(url, data);
};
export const getShippingAddress = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getShippingAddress, param);
  return Get(url);
};
export const setDefaultShippingAddress = (param = "") => {
  const url = getAPIUrl(Globalendpoints.setdefaultShipping, param);
  return Get(url);
};
export const GetParticulaAddressDetail = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.particularAddDetail, param);
  return Post(url, data);
};
export const Checkout = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.checkout, param);
  return Post(url, data);
};
export const CheckoutCart = (param = "") => {
  const url = getAPIUrl(Globalendpoints.chckoutCart, param);
  return Get(url);
};
export const confirmOrder = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.confirmOrder, param);
  return Post(url, data);
};
export const GetOrders = (param = "") => {
  const url = getAPIUrl(Globalendpoints.orderList, param);
  return Get(url);
};
export const GetOrderDetail = (param = "") => {
  const url = getAPIUrl(Globalendpoints.orderDetail, param);
  return Get(url);
};
export const CancelOrder = (param = "") => {
  const url = getAPIUrl(Globalendpoints.cancelOrder, param);
  return Get(url);
};
export const OrderSearch = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.searchProduct, param);
  return Post(url, data);
};

export const getCategoryList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.categoryList, param);
  return Get(url);
};
export const getProductListByCategory = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.ProductListByCategory, param);
  return Post(url,data);
};
export const getWishlist = (param = "") => {
  const url = getAPIUrl(Globalendpoints.wishlist, param);
  return Get(url);
};
export const addWishList = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.addWishList, param);
  return Post(url, data);
};
export const deleteWishList = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.deleteWishList, param);
  return Post(url, data);
};

export const AddReview = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.AddReview, param);
  return Post(url, data);
};
export const EditReview = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.EditReview, param);
  return Post(url, data);
};
export const ChangeUserPassword = (data, param = "") => {
  const url = getAPIUrl(Globalendpoints.changePassword, param);
  return Post(url, data);
};

export const GetPromoCodeList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getPromoCode, param);
  return Get(url);
};

export const GetBrandList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.brandList, param);
  return Get(url);
};
export const verifyPromo = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.verifyPromoCode, param);
  return Post(url,data);
};

export const GetWebsitePages = (param = "") => {
  const url = getAPIUrl(Globalendpoints.websitePages, param);
  return Get(url);
};

export const GetAreaAvailability = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.checkAvailability, param);
  return Post(url,data);
};

export const getPreOrderDetail = (param = "") => {
  const url = getAPIUrl(Globalendpoints.preorderDetail, param);
  return Get(url);
};

export const SavePreOrderDetail = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.preOrderSaveDetail, param);
  return Post(url,data);
};

export const GetReferHistory = (param = "") => {
  const url = getAPIUrl(Globalendpoints.userReferHistory, param);
  return Post(url);
};

export const ContactSend = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.contactQuery, param);
  return Post(url,data);
};