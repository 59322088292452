import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const getDetail = useSelector((state) => state.common.pagesDetail);

  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[#f8f8f8]   font-semibold  px-10 mt-10 md:px-30 lg:px-40">
        <div className="grid  py-16 text-black  grid-cols-2 gap-y-4 sm:grid-cols-3 md:grid-cols-4 place-items-start">
          <div>
            <p className="text-xs text-gray-600 w-1/2">
             SRJ LIVING E-COMMERCE
            </p>
          </div>
          <div className="leading-10 text-gray-600">
            {getDetail?.other_page.map((i, index) => {
              return (
                <p
                  onClick={() => navigate(`/${i.slug}`,{state:i})}
                  className="text-xs cursor-pointer mt-2"
                >
                  {i.page_name}
                </p>
              );
            })}
            {/* <p className="text-xs cursor-pointer mt-2">Privacy Policy</p> */}
            <p
              onClick={() => navigate("/contact-us")}
              className="text-xs cursor-pointer mt-2"
            >
              Contact
            </p>
          </div>
          <div className="text-gray-600">
            <p className="text-xs mt-2">{getDetail?.contact_page?.contact}</p>
            <p className="text-xs mt-2">{getDetail?.contact_page?.email}</p>
            <p className="text-xs mt-2">{getDetail?.contact_page?.address}</p>
          </div>
          <div className="text-gray-600">
            <p className="text-lg mb-4">NewsLetter</p>
            <p className="text-xs mt-2">
              Subscribe our newsletter to get our latest update & news
            </p>
          </div>
        </div>
        <div className="w-full h-[1px] bg-black"></div>
        {/* <div className=" text-xs mt-4 pb-4 text-gray-600 grid grid-cols-1 gap-y-3 sm:grid-cols-2 place-items-start md:place-items-center">
          <div className="flex gap-8 justify-between items-center">
            <p>Copyright ©2023</p>
            <p>Ezulix</p>
            <p>All Rights Reserved</p>
          </div>
          <div className="flex gap-8 justify-between items-center">
            <p>Support</p>
            <p>Terms Of Use</p>
            <p>APrivacy Policy</p>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Footer;
