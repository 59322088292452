import { Drawer, Tree } from "antd";
import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createImageUrl } from "../Utils";
import { clearFilter, setUserAllProfile, setUsers } from "../Redux/userSlice";
import { useNavigate } from "react-router-dom";
import { setOpenLoginModal } from "../Redux/commonSlice";

const SidebarDrawer = ({ categoryList, open, setOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const userProfile = useSelector((state) => state.user.userProfile);
  const { TreeNode } = Tree;

  const renderTreeNodes = (category) => {
    return category.map((item) => {
      if (item.child_category.length > 0) {
        return (
          <TreeNode
            key={item.id}
            title={
              <p
                onClick={() => {
                  navigate("/category", { state: { id: item.id,completed:item } });
                  setOpen(false);
                  dispatch(
                    clearFilter({
                      price: [],
                      rating: null,
                      brandList: [],
                      category: null,
                    })
                  );
                }}
              >
                {item.name}
              </p>
            }
          >
            {renderTreeNodes(item.child_category)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.id}
          title={
            <p
              onClick={() => {
                navigate("/category", { state: { id: item.id,completed:item } });
                setOpen(false);
                dispatch(
                  clearFilter({
                    price: [],
                    rating: null,
                    brandList: [],
                    category: null,
                  })
                );
              }}
            >
              {item.name}
            </p>
          }
        />
      );
    });
  };
  const changePage = (to) => {
    navigate(`/${to}`);
    setOpen(false);
  };
  return (
    <>
      <Drawer
        className="sidebar-drawer"
        title=""
        width={300}
        placement="left"
        closeIcon={
          <div className="flex justify-end items-center">
            <p>
              <IoCloseSharp size={25} color="white" />
            </p>
          </div>
        }
        onClose={() => setOpen(false)}
        open={open}
      >
        <div className="text-white leading-8 font-semibold">
          {isLoggedIn && (
            <div className="flex justify-start items-start gap-x-4">
              <LazyLoadImage
                src={createImageUrl(
                  userProfile?.user_profile?.image,
                  "userProfile"
                )}
                className=" rounded-full w-20"
                crossOrigin="anonymous"
                alt="profile_image"
              />
              <div>
                <p className="capitalize p-0">{`Hi, ${
                  userProfile?.user_profile?.user_first_name ?? ""
                } ${userProfile?.user_profile?.user_last_name ?? ""}`}</p>
                <p className="p-0">{userProfile?.user_profile?.email ?? ""}</p>
              </div>
            </div>
          )}
          <p onClick={() => changePage("")} className="cursor-pointer">
            Home
          </p>
          <p onClick={() => changePage("profile")} className="cursor-pointer">
            My Profile
          </p>{" "}
          <p className="cursor-pointer">Categories</p>
          <Tree className="category-tree" selectable={false}>
            {renderTreeNodes(categoryList)}
          </Tree>
          <p onClick={() => changePage("orders")} className="cursor-pointer">
            My Orders
          </p>
          <p onClick={() => changePage("cart")} className="cursor-pointer">
            My Cart
          </p>
          {!isLoggedIn && (
            <div className="buttons flex gap-2 mt-4 justify-center items-center">
              <p
                onClick={() => {
                  dispatch(setOpenLoginModal(true));
                }}
                className="px-4 py-0 rounded-md cursor-pointer border border-gray-500"
              >
                Signin
              </p>
              <p className="bg-white px-4 py-0 cursor-pointer rounded-md text-primary">
                Signup
              </p>
            </div>
          )}
          {isLoggedIn && (
            <div className="buttons flex gap-2 mt-4 justify-start items-center">
              <p
                onClick={() => (
                  localStorage.clear(),
                  dispatch(setUsers(null)),
                  dispatch(setUserAllProfile(null)),
                  setOpen(false),
                  navigate("/")
                )}
                className="bg-white px-4 py-0 cursor-pointer rounded-md text-primary"
              >
                Logout
              </p>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default SidebarDrawer;
