import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import DefaultLayout from "../Layout/Layout";
function CommerceAuth(props) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
}
export default CommerceAuth;
