import React, { useState } from "react";
import OrderList from "./OrderList";

const MyOrders = () => {
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState("Delivered");
  const Tabs = [
    {
      key: 1,
      name: "Delivered",
      component: <p>jhgegjkhe</p>,
    },
    {
      key: 2,
      name: "Processing",
      component: <p>jhgegjkhe</p>,
    },
    {
      key: 3,
      name: "Cancelled",
      component: <p>jhgegjkhe</p>,
    },
  ];
  return (
    <>
      <div className="md:container md:mx-auto mx-4 mt-20 select-none">
        <div className="flex justify-center items-center">
          <p  className="text-3xl md:text-5xl sectionHeading relative text-textColor font-heading  rounded-sm flex justify-center items-center  cursor-pointer ">
            My Orders
          </p>
        </div>
        <div>
          <div className="mt-8 hidden bg-white rounded-lg md:grid grid-cols-1 md:grid-cols-3 place-items-start md:place-items-center gap-y-4">
            {Tabs.map((i, index) => {
              return (
                <div key={index} className="w-full text-center">
                  <p
                    onClick={() => {
                      setCurrent(index);
                      setStatus(i.name);
                    }}
                    className={`cursor-pointer  transition-all ${
                      i.key - 1 === current ? "font-bold" : ""
                    }  ${
                      i.key - 1 === current
                        ? "bg-black tab-active  text-white"
                        : "border border-[#EBEBEB]"
                    }  py-2 `}
                  >
                    {i.name}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="mt-2  md:hidden grid grid-cols-3  place-items-center md:place-items-center gap-4">
            {Tabs.map((i, index) => {
              return (
                <div key={index} className="w-full text-center">
                  <p
                    onClick={() => {
                      setCurrent(index);
                      setStatus(i.name);
                    }}
                    className={`w-full relative ${
                      i.key - 1 === current
                        ? "border-b tab-active border-b-textColor text-primary"
                        : "border-b text-gray-500 border-b-gray-500"
                    } cursor-pointer  py-2 `}
                  >
                    {i.name}
                  </p>
                </div>
              );
            })}
          </div>
          <OrderList status={status} />
        </div>
      </div>
    </>
  );
};

export default MyOrders;
