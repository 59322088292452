import { Button, Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setFilters } from "../../Redux/userSlice";

const BrandModal = ({ setFilterdata, filters, open, setOpen, allBrands }) => {
  const dispatch = useDispatch();
  const [selectedAlphabet, setSelectedAlphabet] = useState(null);
  const [copyBrand, setCopyBrand] = useState(allBrands);
  const handleSelectAll = () => {
    let data = { ...filters };
    data.brandList = allBrands.map((i) => i.id);
    setFilterdata(data);
  };
  const handleClearAll = () => {
    let data = { ...filters };
    data.brandList = [];
    setFilterdata(data);
  };
  const handleApply = () => {
    dispatch(setFilters(filters));
    setOpen(false);
  };
  useEffect(() => {
    if (selectedAlphabet) {
      let data = [...allBrands];
      data = data.filter((i) =>
        i.name.toLowerCase().startsWith(selectedAlphabet)
      );
      setCopyBrand(data);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAlphabet]);
  const alphabet = "abcdefghijklmnopqrstuvwxyz";
  return (
    <>
      <Modal
        title={
          <p className="font-heading font-medium text-3xl">Choose BRANDS</p>
        }
        footer={null}
        open={open}
        width={1000}
        onCancel={() => setOpen(false)}
      >
        <div className="flex flex-wrap justify-start gap-x-3 items-center">
          {alphabet.split("").map((i,index) => (
            <div key={index} className="flex  flex-col justify-center items-center">
              <div>
                <p
                  onClick={() => setSelectedAlphabet(i)}
                  className={` ${
                    selectedAlphabet === i
                      ? "bg-primary p-1 w-6 h-6 flex justify-center items-center rounded-full text-white"
                      : ""
                  } cursor-pointer font-semibold font-heading uppercase`}
                >
                  {i}
                </p>
              </div>
              <p
                onClick={() => {
                  setCopyBrand(allBrands);
                  setSelectedAlphabet(null);
                }}
                className={`${
                  selectedAlphabet === i ? "block" : "hidden"
                } text-center text-xs cursor-pointer`}
              >
                X
              </p>
            </div>
          ))}
        </div>
        <div className="max-h-[300px] mt-4 overflow-scroll">
          {copyBrand.length>0?
          <div className="grid grid-cols-3 md:grid-cols-4 place-items-start gap-y-3">
            {copyBrand
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((i,index) => {
                return (
                  <Checkbox key={index}
                    onChange={(e) => {
                      let data = { ...filters };
                      if (data.brandList.includes(e.target.value)) {
                        data.brandList = data.brandList.filter(
                          (i, index) => i !== e.target.value
                        );
                      } else {
                        data.brandList = [...data.brandList, e.target.value];
                      }
                      setFilterdata(data);
                    }}
                    value={i.id}
                    checked={filters.brandList.includes(i.id)}
                  >
                    <p className="text-xs uppercase">{i.name}</p>
                  </Checkbox>
                );
              })}
          </div>:<p className="flex justify-center items-center font-semibold text-lg">No Related Filter</p>}
        </div>
        <div className="flex justify-between mt-2 items-center">
          <div className="flex justify-center items-center gap-x-3">
            <p
              onClick={() => handleSelectAll()}
              className="font-heading cursor-pointer"
            >
              Select All
            </p>
            <p onClick={handleClearAll} className="font-heading cursor-pointer">
              Clear All
            </p>
          </div>

          <Button
            onClick={() => handleApply()}
            style={{ color: "white" }}
            className="bg-black w-24 text-center text-white border-none hover:border-none rounded-none"
          >
            Apply
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default BrandModal;
