import { Form, Modal } from "antd";
import React, { useState } from "react";
import { Images } from "../ImageController/Index";
import CommonInput from "../CommmonComponents/CommonInput";
import Commonbutton from "../CommmonComponents/Commonbutton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  createImageUrl,
  dispatchtoast,
  emailPattern,
  mobilePattern,
} from "../Utils";
import { SavePreOrderDetail } from "../ApiServices/Apifun";

const PreOrderModal = ({ isOpen, setIsOpen, detail }) => {
  // function getTimeLeft(targetDate) {
  //   // Convert the target date string to a Date object
  //   const targetDateTime = new Date(targetDate);

  //   // Calculate the difference between the target date and the current date in milliseconds
  //   const difference = targetDateTime - new Date();

  //   // Convert the difference to days, hours, and minutes
  //   const daysLeft = Math.floor(difference / (1000 * 60 * 60 * 24));
  //   const hoursLeft = Math.floor(
  //     (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //   );
  //   const minutesLeft = Math.floor(
  //     (difference % (1000 * 60 * 60)) / (1000 * 60)
  //   );

  //   // Return the time left as an object
  //   return {
  //     days: daysLeft,
  //     hours: hoursLeft,
  //     minutes: minutesLeft,
  //   };
  // }


  const [loading, setLoading] = useState(false);
  const handleSubmit = (val) => {
    setLoading(true);
    SavePreOrderDetail(val)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setIsOpen(false);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  return (
    <Modal
      width={700}
      footer={null}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-7 place-items-center mt-8">
        <div className="">
          <LazyLoadImage
            onError={(err) => {
              err.target.src = Images.NoImage;
              err.target.alt = "No Image";
            }}
            loading="lazy"
            className="w-60"
            src={createImageUrl(detail?.image, "preOrder")}
            alt="loginImage"
          />
        </div>

        <div className="border border-[#DBE7FF] shadow-[0_6px_30px_0px_rgba(0,0,0,0.08)] p-4  ">
          {/* <div className="flex justify-start items-center gap-4">
            <p>
              days :{getTimeLeft(detail.valid_date_time).days} 
            
            </p>
            <p>
              hour: {getTimeLeft(detail.valid_date_time).hours} 
            </p>
            <p>
               minute:{" "}
              {getTimeLeft(detail.valid_date_time).minutes}
            </p>
          </div> */}

          <p className="text-black text-xl font-semibold font-Roboto">
            {detail?.product_name}
          </p>
          <p className="text-[##8D8D8D] text-xs mt-2">{detail?.description}</p>
          <div className="mt-4">
            <Form
              onFinishFailed={(err) => console.log(err)}
              onFinish={(val) => handleSubmit(val)}
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-x-4">
                <Form.Item
                  className="w-full"
                  name={"user_name"}
                  label="Name"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Enter name!",
                    },
                  ]}
                >
                  <CommonInput name={"user_name"} placeholder={"Enter name"} />
                </Form.Item>
                <Form.Item
                  className="w-full"
                  name={"user_email"}
                  label="Email"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Enter email!",
                    },
                    {
                      pattern: emailPattern,
                      message: "Enter valid email",
                    },
                  ]}
                >
                  <CommonInput
                    name={"user_email"}
                    placeholder={"Enter email"}
                  />
                </Form.Item>
                <Form.Item
                  className="w-full"
                  name={"user_mobile"}
                  label="Mobile"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Enter mobile!",
                    },
                    {
                      pattern: mobilePattern,
                      message: "Enter valid mobile",
                    },
                  ]}
                >
                  <CommonInput
                    onInput={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                    maxlength={10}
                    name={"user_mobile"}
                    placeholder={"Enter mobile "}
                  />
                </Form.Item>
                {/* <Form.Item
                  className="w-full"
                  name={"qty"}
                  label="Quantity"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Enter quantity!",
                    },
                  ]}
                >
                  <CommonInput
                    onInput={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                    name={"qty"}
                    placeholder={"Enter quantity "}
                  />
                </Form.Item> */}
              </div>
              <Form.Item
                className="w-full"
                name={"desc"}
                label="Description"
                labelCol={{ span: 24 }}
              >
                <CommonInput name={"desc"} placeholder={"Enter description"} />
              </Form.Item>
              <Form.Item>
                <div className="flex  justify-center items-center">
                  <Commonbutton
                    loading={loading}
                    htmltype="submit"
                    className="bg-black border-none rounded-none text-white focus:outline-none w-[227px] hover:border-none hover:outline-none"
                    text={"Submit"}
                  />
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PreOrderModal;
