import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { GetOrderDetail } from "../../ApiServices/Apifun";
import { Button, Skeleton, Spin } from "antd";
import { createImageUrl } from "../../Utils";
import { Images } from "../../ImageController/Index";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WriteReviewModal from "../../components/WriteReview";

const OrderDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [address, setAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState({});
  const [listSkeleton, setListSkeleton] = useState(false);
  const [openReviewModal, setOpenreviewModal] = useState(false);
  const [productId, setProducrId] = useState(null);
  const particularOrderDetail = () => {
    setListSkeleton(true);
    setLoading(true);
    GetOrderDetail(`${location.state}`)
      .then((res) => {
        if (res.status) {
          setOrderDetail(res.data.order_detail);
          setAddress(res.data.addresses?.shipping_addr);
          setProducts(res.data.product_detail);
          // const total = res.data.product_detail.reduce((a, b) => {
          //   a = a + b.item_price;
          //   return a;
          // }, 0);
          // setTotalAmount(total);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setListSkeleton(false);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!location.state) {
      navigate(-1);
    } else {
      particularOrderDetail();
    }
    //eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="md:container md:mx-auto mx-4 mt-20 select-none">
        <div className="flex justify-center items-center">
        <p  className="text-3xl md:text-5xl sectionHeading relative text-textColor font-heading rounded-sm flex justify-center items-center  cursor-pointer ">
          Order Detail
        </p>
        </div>
        <div className="flex justify-end mt-2 items-center">
          <p
            style={{
              color: orderDetail.color_code
                ? orderDetail.color_code
                : "#000000",
            }}
            className="font-bold text-2xl mx-2  capitalize font-heading "
          >
            {orderDetail.order_status}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 place-items-start mt-10 gap-x-8">
          <div className="col-span-2  w-full ">
            <Spin spinning={loading}>
              <div className="grid grid-cols-4 font-semibold place-items-center">
                <div className="col-span-2">
                  <p>Product</p>
                </div>
                <div>
                  <p>Price</p>
                </div>
                <div>
                  <p>Quantity</p>
                </div>
              </div>
              <div className="w-full border border-gray-200 mt-4 h-[1px]"></div>
              {listSkeleton ? (
                <>
                  <Skeleton active={true} className="mt-2" />
                  <Skeleton active={true} className="mt-2" />
                </>
              ) : products?.length !== 0 ? (
                products?.map((i, index) => {
                  const image = i.productOptionDetail.map((i) => {
                    return {
                      ...i,
                      option_images:
                        typeof i.option_images == "string"
                          ? JSON.parse(i.option_images)
                          : i.option_images,
                    };
                  });
                  return (
                    <>
                      <div className="grid relative grid-cols-4 mt-2 font-semibold place-items-center">
                        <div className="col-span-2 w-full">
                          <div className="flex w-full justify-center gap-8 items-center">
                            <LazyLoadImage
                            onError={(err)=>{
                              err.target.src=Images.NoImage
                              err.target.alt="No Image"
                            }}
                              src={createImageUrl(
                                image.length !== 0
                                  ? image.filter(
                                      (i) => i.option_name === "color"
                                    )[0]?.option_images[0]
                                  : i.defaultImageDetail[0].image,"order"
                              )}
                              alt="itemImage"
                              crossOrigin="anonymous"
                              className="w-24 m-auto rounded-lg"
                            />
                            <div className="w-full">
                              <NavLink
                                to={`/productDesc/${i.slug}`}
                                state={{ id: i.slug }}
                              >
                                <p className="cursor-pointer">
                                  {" "}
                                  {i.product_model}
                                </p>
                              </NavLink>
                              <p className="text-xs"> {i.product_name}</p>
                              {i.productOptionDetail.map((i, index) => (
                                <p key={index} className="capitalize text-gray-700 text-xs">
                                  {i.option_name}: {i.option_value_name}
                                </p>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setOpenreviewModal(true);
                            setProducrId(i.product_id);
                          }}
                          className="absolute -top-1 cursor-pointer right-0"
                        >
                          <p className=" text-[10px] bg-black text-white p-1 rounded-sm">
                            Write Review
                          </p>
                        </div>
                        <div>
                          <p>{orderDetail.currency==="INR"?"₹":"$"} {i.item_price}</p>
                        </div>
                        <div>
                          <p>Units: {i.quantity}</p>
                        </div>
                      </div>

                      <div className="w-full border border-gray-200 mt-4 h-[1px]"></div>
                    </>
                  );
                })
              ) : (
                <div className="flex justify-center items-center">
                  <div>
                    <LazyLoadImage
                    onError={(err)=>{
                      err.target.src=Images.NoImage
                      err.target.alt="No Image"
                    }}
                      src={Images.EmptyCart}
                      alt="empty-cart"
                      className="w-36"
                    />
                    <p className="text-center bg-primary p-3 rounded-sm transition-all hover:scale-x-105 cursor-pointer font-semibold text-white ">
                      Explore Products
                    </p>
                  </div>
                </div>
              )}
            </Spin>
          </div>
          <div className="w-full mt-6 md:mt-0  bg-[#f8f8f8]  p-4">
            <Spin spinning={loading}>
              <div className="flex justify-between items-center">
                <p className="font-bold text-xs w-20 text-gray-500">
                  Shipping Address:
                </p>
                <p className="font-bold w-44 text-xs">
                  <span>
                    {address?.addr_address1} {address?.addr_address2}{" "}
                    {address?.addr_city}, {address?.addr_state_name},{" "}
                    {address?.addr_country_name}
                  </span>
                </p>
              </div>
              <div className="flex justify-between mt-4 items-center">
                <p className="font-bold text-xs text-gray-500">
                  Payment Status:
                </p>
                <p className="font-bold w-44 text-xs capitalize">
                  {orderDetail.payment_status}
                </p>
              </div>
              <div className="flex justify-between mt-4 items-center">
                <p className="font-bold text-xs text-gray-500">
                  Payment Method:
                </p>
                <p className="font-bold w-44 text-xs">
                  {orderDetail.payment_type}
                </p>
              </div>
              <div className="flex justify-between mt-4 items-center">
                <p className="font-bold text-xs text-gray-500">
                  Delivery Method:
                </p>
                <p className="font-bold w-44 text-xs">E-cart</p>
              </div>
              <div className="flex justify-between mt-4 items-center">
                <p className="font-bold text-xs text-gray-500">Order Amount:</p>
                <p className="font-bold w-44 text-xs">{orderDetail.total_amount??0} {orderDetail.currency==="INR"?"₹":"$"}</p>
              </div>
              <div className="flex justify-between mt-4 items-center">
                <p className="font-bold text-xs text-gray-500">Coupon Applied:</p>
                <p className="font-bold w-44 text-xs">{orderDetail.discounted_amount??0} {orderDetail.currency==="INR"?"₹":"$"}</p>
              </div>
              <div className="flex justify-between mt-4 items-center">
                <p className="font-bold text-xs text-gray-500">Total Amount:</p>
                <p className="font-bold w-44 text-xs">{orderDetail.final_order_amount??0} {orderDetail.currency==="INR"?"₹":"$"}</p>
              </div>
              <div className="flex w-full mt-8 gap-4 justify-between items-center">
                <Button
                onClick={()=>navigate("/")}
                  style={{ color: "white" }}
                  className="w-full rounded-sm bg-black font-bold text-xs text-center h-10 text-white border-none hover:border-none outline-none hover:outline-none"
                >
                  Reorder
                </Button>
                {/* <Button
                  onClick={() => setOpenreviewModal(true)}
                  style={{ color: "white" }}
                  className="w-full rounded-sm bg-primary font-bold text-xs text-center h-10 text-white border-none hover:border-none outline-none hover:outline-none"
                >
                  Leave Feedback
                </Button> */}
              </div>
            </Spin>
          </div>
        </div>
      </div>
      {openReviewModal && (
        <WriteReviewModal
          productId={productId}
          setOpen={setOpenreviewModal}
          open={openReviewModal}
        />
      )}
    </>
  );
};

export default OrderDetail;
