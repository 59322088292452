import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorageItem } from "../Utils";

const initialState = {
  userDetail: null,
  userProfile: null,
  selectedCategory:null,
  filters: {
    price: [],
    rating: null,
    brandList: [],
    category: null,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.userDetail = action.payload;
    },
    setUserAllProfile: (state, action) => {
      state.userProfile = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    clearFilter: (state, action) => {
      state.filters = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUsers, setUserAllProfile,setSelectedCategory, setFilters, clearFilter } =
  userSlice.actions;

export default userSlice.reducer;
