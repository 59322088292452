import { Drawer, Skeleton, Tree } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearFilter, setSelectedCategory } from "../Redux/userSlice";

const CategoryDrawer = ({ open, setOpen, category }) => {
  const { TreeNode } = Tree;
  const dispatch=useDispatch()
  const navigate = useNavigate();
  const [openkey, setopenkey] = useState([]);
  const [show, setShow] = useState(false);
  const renderTreeNodes = (category) => {
    return category.map((item) => {
      if (item.child_category.length > 0) {
        return (
          <TreeNode
            key={item.id}
            title={
              <div className="capitalize hover:bg-gray-300 rounded-lg text-gray-500 hover:text-primary p-2 transition-all w-full">
                <p onClick={() => {
                  navigate("/category", { state: { id: item.id,completed:item } });
                  setOpen(false);
                  dispatch(setSelectedCategory(item.id))
                  dispatch(
                    clearFilter({
                      price: [],
                      rating: null,
                      brandList: [],
                      category: null,
                    })
                  );
                }}> {item.name}</p>
              </div>
            }
          >
            {renderTreeNodes(item.child_category)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.id}
          className="w-full"
          title={
            <div
              onClick={() => {
                navigate("/category", { state: { id: item.id,completed:item } });
                setOpen(false);
                dispatch(setSelectedCategory(item.id))
                dispatch(
                  clearFilter({
                    price: [],
                    rating: null,
                    brandList: [],
                    category: null,
                  })
                );
              }}
              className="flex justify-start items-centerhover:bg-gray-300 rounded-lg text-gray-500 hover:text-primary p-2 transition-transform w-full"
            >
              <p className="capitalize ">{item.name}</p>
            </div>
          }
        />
      );
    });
  };
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 500);
  }, []);
  return (
    <Drawer
      width={350}
      closeIcon={null}
      onClose={() => setOpen(false)}
      placement="left"
      open={open}
    >
      {show ? (
        <Tree
          //   switcherIcon={(e) => (
          //     <p>
          //       <FaChevronRight />
          //     </p>
          //   )}
          
          onExpand={(e) => setopenkey(e)}
          showIcon={true}
          expandedKeys={openkey}
          //    onSelect={(e)=>setopenkey(e)}
          //    selectedKeys={openkey}
          className="individualCategory"
        >
          {renderTreeNodes(category)}
        </Tree>
      ) : (
        [1, 2, 3, 4, 4, 45, 5, 3, 3, 3, 3].map((i, index) => (
          <Skeleton.Input key={index} className="mt-2 skeleton" active />
        ))
      )}
      {/* {} */}
    </Drawer>
  );
};

export default CategoryDrawer;
