import { Form, Modal } from "antd";
import React, { useState } from "react";
import { Images } from "../ImageController/Index";
import CommonInput from "../CommmonComponents/CommonInput";
import Commonbutton from "../CommmonComponents/Commonbutton";
import { useDispatch, useSelector } from "react-redux";
import { setOpenLoginModal, setOpenSignUpModal } from "../Redux/commonSlice";
import { userLogin } from "../ApiServices/Apifun";
import { setUsers } from "../Redux/userSlice";
import {  setLocalStorageItem } from "../Utils";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LoginModal = () => {
  const openModal = useSelector((state) => state.common.openLoginModal);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const handleLogin = (val) => {
    setLoading(true);
    userLogin(val)
      .then((res) => {
        if (res.status) {
          dispatch(setUsers(res.user));
          dispatch(setOpenLoginModal(false));
          setLocalStorageItem("accessToken", res.access_token);
          setLocalStorageItem("refreshToken", res.referesh_token);
          window.location.reload();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Modal
      width={700}
      footer={null}
      open={openModal}
      onCancel={() => dispatch(setOpenLoginModal(false))}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center mt-8">
        <div className="hidden sm:block">
          <LazyLoadImage onError={(err)=>{
                              err.target.src=Images.NoImage
                              err.target.alt="No Image"
                            }} loading="lazy" src={Images.Login} alt="loginImage" />
        </div>
        <div className="border border-[#DBE7FF] shadow-[0_6px_30px_0px_rgba(0,0,0,0.08)] p-4  ">
          <p className="text-black text-xl font-semibold font-Roboto">
            Sign in to E-commerce{" "}
          </p>
          <p className="text-[##8D8D8D] text-xs mt-2">
            Sign in to your account and make recharges and bookings faster
          </p>
          <div className="mt-4">
            <Form
              onFinishFailed={(err) => console.log(err)}
              onFinish={(val) => handleLogin(val)}
            >
              <Form.Item
                name={"username"}
                label="Username"
                labelCol={{span:24}}
                rules={[
                  {
                    required: true,
                    message: "Mobile or Email is required!",
                  },
                ]}
              >
                {/* <p className="font-medium font-Roboto text-sm mb-1">
                  Enter Mobile Number/ Username
                </p> */}
                <CommonInput
                  name={"username"}
                  placeholder={"Enter Mobile Number/Email"}
                />
              </Form.Item>
              <Form.Item
                name={"password"}
                label="Password"
                labelCol={{span:24}}
                rules={[
                  {
                    required: true,
                    message: "Password is required!",
                  },
                ]}
              >
                {/* <p className="font-medium font-Roboto text-sm mb-1">
                  Enter Password
                </p> */}
                <CommonInput name={"password"} placeholder={"Your Password"} />
              </Form.Item>
              <div className="flex justify-end items-center">
                <p className="text-xs mt-1 cursor-pointer text-svgBorder font-Roboto">
                  Forgot Password?
                </p>
              </div>
              <Form.Item>
                <div className="flex mt-4 justify-center items-center">
                  <Commonbutton
                    loading={loading}
                    htmltype="submit"
                    className="bg-black border-none text-white focus:outline-none w-[227px] hover:border-none hover:outline-none"
                    text={"Sign In"}
                  />
                </div>
                <div
                  onClick={() => dispatch(setOpenSignUpModal(true))}
                  className="flex justify-center items-center"
                >
                  <p className="text-xs mt-2 cursor-pointer text-svgBorder font-Roboto">
                    Don't have an account? Sign Up.
                  </p>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
