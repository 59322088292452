import { Button, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GetCartList } from "../ApiServices/Apifun";
import { Images } from "../ImageController/Index";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { createImageUrl, getDecryptedLocalStorage, getSymbol } from "../Utils";
import { useNavigate } from "react-router-dom";

const CartDrawer = ({ open, setOpen }) => {
  const navigate = useNavigate();
  const [totalAmount, setTotalAmount] = useState(0);
  //   const [listSkeleton, setListSkeleton] = useState(false);
  const [cartList, setCartList] = useState([]);

  const isLoggedIn = useSelector((state) => state.user.userDetail);
  const GetCartData = () => {
    // setListSkeleton(true);
    GetCartList()
      .then((res) => {
        if (res.status) {
          setCartList(res.data);
          const total = res.data
            .filter((i) => i.is_out_of_stock !== 1)
            .reduce((a, b) => {
              a = a +(getDecryptedLocalStorage('country')==="IN"? b.total_qty_price_inr:b.total_qty_price_usd);
              return a;
            }, 0);
          setTotalAmount(total);
        }
      })
      .catch((err) => console.log(err));
    //   .finally(() => setListSkeleton(false));
  };

  useEffect(() => {
    if (isLoggedIn) {
      GetCartData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Drawer
        // closeIcon={null}
        title={"Cart"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="max-h-[350px] overflow-scroll">
          {cartList.length !== 0 ? (
            cartList.map((i, index) => {
              const image = i.productOptionDetail.map((i) => {
                return {
                  ...i,
                  option_images:
                    typeof i.option_images == "string"
                      ? JSON.parse(i.option_images)
                      : i.option_images,
                };
              });
              return (
                <div key={index} className="flex py-6">
                  <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                  <LazyLoadImage
                      crossOrigin="anonymous"
                        onError={(err) => {
                          err.target.src = Images.NoImage;
                          err.target.alt = "No Image";
                        }}
                        src={createImageUrl(
                          image.length !== 0
                            ? image.filter((i) => i.option_name === "color")[0]
                                ?.option_images[0]
                            : i.defaultImageDetail[0].image
                        )}
                        className="h-full w-full object-cover object-center"
                        alt="product"
                      />
                    {/* <img
                      src={product.imageSrc}
                      alt={product.imageAlt}
                      className="h-full w-full object-cover object-center"
                    /> */}
                  </div>

                  <div className="ml-4 flex flex-1 flex-col">
                    <div>
                      <div className="flex justify-between text-base capitalize font-semibold text-gray-400">
                          <p>{i.product_name}</p>
                          {i.is_shop_user_available !== 1 &&
                      i.is_created_user_available !== 1 ? (
                          <p className="text-red-500 text-sm ml-4 font-semibold">
                            Seller not active
                          </p>
                      ) : i.is_all_options_available !== 1 ? (
                          <p className="text-red-500 text-sm ml-4 font-semibold">
                            Product Unavailable
                          </p>
                      ) : i.is_out_of_stock === 1 ? (
                          <p className="text-red-500 text-sm ml-4 font-semibold">
                            OUT OF STOCK
                          </p>
                      ) : (
                        <p className="ml-4 text-xs text-black">
                          {getSymbol()} {getDecryptedLocalStorage('country')==="IN"?i.total_qty_price_inr:i.total_qty_price_usd}.00
                        </p>
                      )}
                      </div>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-sm">
                      <p className="text-gray-500">Qty {i.product_qty}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-center items-center">
              <div>
                <LazyLoadImage
                  onError={(err) => {
                    err.target.src = Images.NoImage;
                    err.target.alt = "No Image";
                  }}
                  src={Images.EmptyCart}
                  alt="empty-cart"
                  className="w-36"
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-between items-center mt-4">
          <p className="font-bold">Subtotal :</p>
          <p className="text-red-400 font-bold">{totalAmount} Rs.</p>
        </div>
        <div className="w-full">
          <Button
            onClick={() => {
              navigate("/cart");
              setOpen(false);
            }}
            style={{ color: "white" }}
            className="bg-black hover:bg-red-500 h-14 w-full mt-4 font-semibold transition-all text-white rounded-sm border-none hover:border-none"
          >
            View Cart
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default CartDrawer;
