import { Modal } from "antd";
import React from "react";
import Productdesc from "../ProductDesc/Productdesc";

const QuickOverView = ({ open, setopen, productId }) => {
  return (
    <>
      <Modal style={{top:"30px"}} footer={null} width={1500} open={open} onCancel={() => setopen(false)}>
        <Productdesc productId={productId} />
      </Modal>
    </>
  );
};

export default QuickOverView;
