import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, Select, Spin } from "antd";
import {
  AddShippingAddress,
  EditShippingAddress,
  GetCountry,
  GetParticulaAddressDetail,
  GetStates,
  GetUserDetail,
} from "../ApiServices/Apifun";
import {
  dispatchtoast,
  filterOption,
  mobilePattern,
  pincodePattern,
} from "../Utils";
import { setUserAllProfile } from "../Redux/userSlice";
import { useDispatch } from "react-redux";

const AddressField = ({ apiCall = () => {}, recordId, setShowAddForm }) => {
  const formref = useRef();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);

  const getAllCountry = (val = 101) => {
    GetCountry()
      .then((res) => {
        if (res.status) {
          setCountry(
            res.data.map((i) => {
              return {
                label: i.countryName,
                value: Number(i.countryId),
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  };
  const particular = () => {
    setPageLoading(true);
    GetParticulaAddressDetail({ record_id: recordId })
      .then((res) => {
        if (res.status) {
          handleGetState(res.data.addr_country_id);
          formref.current.setFieldsValue({
            addr_title: res.data.addr_title,
            addr_name: res.data.addr_name,
            addr_address1: res.data.addr_address1,
            addr_address2: res.data.addr_address2,
            addr_country_id: Number(res.data.addr_country_id),
            addr_state_id: Number(res.data.addr_state_id),
            addr_city: res.data.addr_city,
            addr_zip: res.data.addr_zip,
            addr_phone: res.data.addr_phone,
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoading(false));
  };
  useEffect(() => {
    getAllCountry();
    if (recordId) {
      particular();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function handleGetState(val) {
    formref.current.setFieldsValue({"addr_state_id":""})
    GetStates(val)
      .then((res) => {
        if (res.status) {
          setStates(
            res.data.map((i) => {
              return {
                label: i.stateName,
                value: Number(i.stateId),
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  }
  const handleAdd = (val) => {
    setLoading(true);
    AddShippingAddress({
      ...val,
      addr_state_id: states.length > 0 ? val.addr_state_id : 1,
      addr_phone_dcode: "+91-ind",
    })
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setShowAddForm(false);
          apiCall();
          GetUserDetail()
            .then((res) => {
              if (res.status) {
                dispatch(setUserAllProfile(res.data));
              }
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleEdit = (val) => {
    setLoading(true);
    EditShippingAddress({
      ...val,
      record_id: recordId,
      addr_state_id: states.length > 0 ? val.addr_state_id : 1,
      addr_phone_dcode: "+91-ind",
    })
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setShowAddForm(false);
          apiCall();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const className =
    "h-10 rounded-none w-full outline-none hover:outline-none border-t-0 border-l-0 border-r-0 border-b border-black";
  return (
    <div>
      <Spin spinning={pageLoading}>
        <Form
          onFieldsChange={(val) => {
            if (val[0].name[0] === "addr_country_id") {
              handleGetState(val[0].value);
            }
          }}
          form={form}
          ref={formref}
          onFinish={(val) => (recordId ? handleEdit(val) : handleAdd(val))}
          onFinishFailed={(err) => console.log(err)}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 place-items-start">
            <Form.Item
              className="w-full"
              name={"addr_title"}
              rules={[
                {
                  required: true,
                  message: "Address Label Is Mandatory",
                },
              ]}
              label="Address Label"
              labelCol={{ span: 24 }}
            >
              <Input.TextArea
                maxLength={50}
                placeholder="Address label"
                showCount
                className={className}
              />
            </Form.Item>
            <Form.Item
              className="w-full"
              name={"addr_name"}
              rules={[
                {
                  required: true,
                  message: "Name Is Mandatory",
                },
              ]}
              label="Name"
              labelCol={{ span: 24 }}
            >
              <Input placeholder="Name" className={className} />
            </Form.Item>
            <Form.Item
              className="w-full"
              name={"addr_address1"}
              rules={[
                {
                  required: true,
                  message: "Address Line 1 Is Mandatory",
                },
              ]}
              label="Address Line 1"
              labelCol={{ span: 24 }}
            >
              <Input.TextArea
                maxLength={100}
                showCount
                placeholder="Address line 1"
                className={className}
              />
            </Form.Item>
            <Form.Item
              className="w-full"
              name={"addr_address2"}
              label="Address Line 2"
              labelCol={{ span: 24 }}
            >
              <Input.TextArea
                placeholder="Address line 2"
                maxLength={100}
                showCount
                className={className}
              />
            </Form.Item>
            <Form.Item
              name="addr_country_id"
              className="w-full"
              label="Country"
              labelCol={{ span: 24 }}
            >
              <Select
                // onChange={(val) => handleGetState(val)}
                placeholder={"Select Country"}
                options={country}
                showSearch
                filterOption={filterOption}
                className="w-full rounded-none h-10"
              />
            </Form.Item>
            {states.length>0 && <Form.Item
              name={"addr_state_id"}
              rules={[
                {
                  required: true,
                  message: "State Is Mandatory",
                },
              ]}
              className="w-full"
              label="State"
              labelCol={{ span: 24 }}
            >
              <Select
                showSearch
                filterOption={filterOption}
                placeholder={"Select state"}
                options={states}
                className="w-full h-10"
              />
            </Form.Item>}
            <Form.Item
              className="w-full"
              name={"addr_city"}
              rules={[
                {
                  required: true,
                  message: "City Is Mandatory",
                },
              ]}
              label="City"
              labelCol={{ span: 24 }}
            >
              <Input maxLength={50} showCount className={className} />
            </Form.Item>
            <Form.Item
              className="w-full"
              name={"addr_zip"}
              rules={[
                {
                  required: true,
                  message: "Postal code Is Mandatory",
                },
                {
                  pattern: pincodePattern,
                  message: "Invalid Postal code",
                },
              ]}
              label="Postal Code"
              labelCol={{ span: 24 }}
            >
              <Input placeholder="Postal Code" className={className} />
            </Form.Item>
            <Form.Item
              className="w-full"
              name={"addr_phone"}
              rules={[
                {
                  required: true,
                  message: "Please Enter Valid Phone Number Format.",
                },
                {
                  pattern: mobilePattern,
                  message: "invalid mobile number",
                },
              ]}
              label="Phone"
              labelCol={{ span: 24 }}
            >
              <Input placeholder="Mobile" className={className} />
            </Form.Item>
          </div>
          <div className="flex justify-between items-center">
            <Button
              style={{ color: "black" }}
              className=" text-black  border border-black outline-none hover:outline-none w-24"
            >
              Reset
            </Button>
            <Button
              loading={loading}
              htmlType="submit"
              style={{ color: "white" }}
              className="bg-primary text-white hover:border-none border-none outline-none hover:outline-none w-24"
            >
              {recordId ? "Edit" : "Save"}
            </Button>
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default AddressField;
