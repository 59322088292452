import React from "react";
import Filtercomponent from "./Filtercomponent";
import { Drawer } from "antd";

const FilterDrawer = ({
  open,
  setOpen,
  data,
  setSelectedId,
  selectedId,
  brands,
}) => {
  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Filtercomponent
        setOpen={setOpen}
          brands={brands}
          data={data}
          setSelectedId={setSelectedId}
          selectedId={selectedId}
        />
      </Drawer>
    </>
  );
};

export default FilterDrawer;
